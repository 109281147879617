import React from "react";
import { IconEdit, IconMoveFile, IconOpen, IconPlus, IconShare, IconTrash, IconUnshare, IconWarning } from "icons";
import clsx from "clsx";
import { useTrainingsContext } from "../TrainingsContext";
import { useNavigate } from "react-router-dom";
import { AnalyticsEvent } from "utils";

const ActionMenu = ({ data, isTraining = true, setShowActionMenu, setShowRenameModal, setShowDeleteModal, imageError }: any) => {
  const { onSharedWithMePage } = useTrainingsContext();
  const navigate = useNavigate();

  const DeleteWarning = () => {
    return (
      <div className="flex items-center w-[240px] gap-[5px] p-2 rounded-[5px] text-orange border border-orange bg-[#170D08]">
        <IconWarning className="flex-shrink-0 w-[14px] h-[14px]" />
        <span className="text-bodySm">The training data should be deleted by the creator</span>
      </div>
    );
  };

  const MenuItem = ({ icon, title, onClick, hidden, disabled }: any) => {
    const Icon = icon;

    return (
      <div className="relative">
        <div
          className={clsx(
            "peer items-center p-2.5 gap-2.5 text-grey text-bodyMd rounded-[4px]",
            hidden ? "hidden" : "flex",
            disabled ? "cursor-auto opacity-50 !text-grey" : "cursor-pointer hover:text-white hover:bg-black-300"
          )}
          onClick={() => {
            if (disabled) return;
            setShowActionMenu(false);
            onClick();
          }}
        >
          <Icon className="w-[18px] h-[18px]" />
          {title}
        </div>

        {disabled && title === "Delete" && (
          <div className="absolute hidden peer-hover:block top-[52px] left-1/2 -translate-x-1/2">
            <DeleteWarning />
          </div>
        )}
      </div>
    );
  };

  function getDesignType(type: any) {
    switch (type) {
      case 1:
        return "design";
      case 2:
        return "attribute";
      case 3:
        return "pattern";
      default:
        break;
    }
  }

  return (
    <div className="flex flex-col bg-black rounded-[5px] p-1.5 w-[200px] border border-black-300">
      {data?.status === 2 && (
        <MenuItem
          icon={IconPlus}
          title="Create Design With"
          onClick={() => {
            if (window.location.pathname === "/") AnalyticsEvent("homepage_mytrainings_create_design_with", "", "click");
            else {
              AnalyticsEvent("mytrainings_create_design_with", "", "click");
            }
            navigate("/design", { state: { trainingData: data, designType: getDesignType(data?.group_id) } });
          }}
        />
      )}
      {isTraining && !imageError && (
        <MenuItem
          icon={IconOpen}
          title="Open"
          onClick={() => {
            if (window.location.pathname === "/") AnalyticsEvent("homepage_mytrainings_open", "", "click");
            else AnalyticsEvent("mytrainings_open", "", "click");
            navigate(`/new-training/${data.id}`);
          }}
        />
      )}
      <MenuItem
        hidden={onSharedWithMePage}
        icon={IconEdit}
        title="Rename"
        onClick={() => {
          if (window.location.pathname === "/") AnalyticsEvent("homepage_mytrainings_rename", "", "click");
          else AnalyticsEvent("mytrainings_rename", "", "click");
          setShowRenameModal(true);
        }}
      />
      <MenuItem icon={IconMoveFile} title="Move" onClick={() => console.log("move")} />
      {/* <MenuItem hidden={onSharedWithMePage} icon={IconUnshare} title="Unshare" onClick={() => console.log("unshare")} />
      <MenuItem hidden={onSharedWithMePage} icon={IconShare} title="Share" onClick={() => console.log("share")} /> */}
      <MenuItem
        hidden={onSharedWithMePage}
        icon={IconTrash}
        title="Delete"
        disabled={data?.is_own === 0}
        onClick={() => {
          if (window.location.pathname === "/") AnalyticsEvent("homepage_mytrainings_delete", "", "click");
          else AnalyticsEvent("mytrainings_delete", "", "click");
          setShowDeleteModal(true);
        }}
      />
    </div>
  );
};

export default ActionMenu;
