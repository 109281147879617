import React, { useState } from "react";
import { IconAttribute, IconMore, IconPattern, IconPlay, IconPremiumFeature, IconShared, IconStyle } from "icons";
import { ImageDefault, ImageTrainingFallback } from "images";
import clsx from "clsx";
import ActionMenu from "./ActionMenu";
import { useClickOutside } from "hooks/useClickOutside";
import { TrainingStatus, useTrainingsContext } from "../TrainingsContext";
import Like from "./Like";
import Button from "components/Button";
import RenameModal from "components/RenameModal";
import DeleteModal from "components/DeleteModal";
import LazyImg from "components/LazyImg";
import { useDrag, useDrop } from "react-dnd";
import trainService from "api/train/train.service";
import { useNavigate } from "react-router-dom";
import { AnalyticsEvent, removeBackslash } from "utils";
import { queueCountProvider } from "pages/NewTrainingPage/StartTrainingModal";

const InProgress = ({ className, queueCount, startDate }: any) => {
  const givenDate = new Date(startDate + "Z"); // Ensure the date is treated as GMT-0
  const now = new Date();
  const differenceInMilliseconds = now.getTime() - givenDate.getTime();
  const res = Math.floor(differenceInMilliseconds / 1000);

  // Calculate remaining time and ensure it’s at least 0
  let remainingTime = Math.max(3600 - res, 0);

  // Calculate the percentage of time passed
  let progressPercentage = ((3600 - remainingTime) / 3600) * 100;

  if (!startDate) {
    remainingTime = 0;
    progressPercentage = 50;
  }

  return (
    <div className={clsx("flex flex-col p-2.5 gap-2.5 bg-black-300 w-full rounded-[3px]", className)}>
      <div className="flex justify-between text-h7 text-white">
        {queueCount === 0 || queueCount === 1 ? <span>In Progress</span> : <span>You are now {queueCountProvider(queueCount)} in line.</span>}
      </div>
      {startDate && (
        <div className="relative h-[2px] bg-black w-full">
          <div
            className={clsx("h-[2px] bg-green", startDate ? "animate-training-progress" : "w-1/2")}
            style={
              {
                width: `${progressPercentage}%`,
                "--training-animation-width": progressPercentage + "%",
                "--training-animation-duration": remainingTime + "s",
              } as React.CSSProperties
            }
          />
        </div>
      )}
    </div>
  );
};

export const ItemTypes = {
  TRAINING: "training",
};

const TrainingsItem = ({ data, onStartTrain, onEditTrain, onDeleteTrain, isPremium }: any) => {
  const { setTrainings } = useTrainingsContext();
  const navigate = useNavigate();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [disableStartTrainingBtn, setDisableStartTrainingBtn] = useState(false);
  const [imageError, setImageError] = useState(false);

  function handleImageError() {
    setImageError(true);
  }

  useClickOutside(containerRef, () => {
    setShowActionMenu(false);
  });

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.TRAINING,
    item: { id: data.id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.TRAINING,
      drop: (droppedItem: any) => {
        // Prevent the item from being dropped onto itself
        if (droppedItem.id === data.id) return;

        trainService
          .addTraintoTrain(data.id, {
            in_train_id: droppedItem.id,
          })
          .then(() => setTrainings((prev: any) => prev.filter((item: any) => item.id !== droppedItem.id)));
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    []
  );

  function renderIcon() {
    switch (data?.group_id) {
      case 1:
        return <IconStyle className="flex-shrink-0 text-trueBlack dark:text-green w-[18px] h-[18px]" />;
      case 2:
        return <IconAttribute className="flex-shrink-0 text-purple w-[18px] h-[18px]" />;
      case 3:
        return <IconPattern className="flex-shrink-0 text-pink w-[18px] h-[18px]" />;
      default:
        break;
    }
  }

  return (
    <div ref={drop} className={clsx("flex gap-3 relative p-3 border border-black-200 rounded-md w-full", isPremium ? "pointer-events-none" : "")}>
      {isPremium && <IconPremiumFeature className="absolute left-1 top-1" />}

      <RenameModal
        name={data?.name}
        trainId={data?.id}
        onClose={() => setShowRenameModal(false)}
        show={showRenameModal}
        onEdit={(newName: string) => {
          onEditTrain(data.id, {
            name: newName,
            directory_id: data.directory_id,
            avatar_path: data.avatar_path,
            description: "",
            status: data.status,
            group_id: data.group_id,
            training_class: data?.training_class ?? "",
          });
        }}
      />
      <DeleteModal trainId={data?.id} onClose={() => setShowDeleteModal(false)} show={showDeleteModal} onDelete={onDeleteTrain} />
      <div className={clsx("relative flex flex-col gap-3 rounded-md w-full", isPremium ? "pointer-events-none opacity-50" : "")} ref={drag}>
        <div className="relative flex items-center gap-[5px]">
          {renderIcon()}
          <div className="flex items-center gap-[5px] w-full custom-line-clamp-1">
            <span className="text-white text-h6 custom-line-clamp-1" title={data?.name}>
              {data?.name}
            </span>
            {/* <IconShared className="w-4 h-4 text-grey" /> */}
            <span className="text-grey text-bodySm">{data?.status === TrainingStatus.Draft ? "(Draft)" : ""}</span>
          </div>
          <Like
            location={window.location.pathname === "/" ? "homepage_trainings_item" : removeBackslash(window.location.pathname) + "_trainings_item"}
            isLiked={data?.is_liked}
            object_id={data.id}
            object_type="training"
            likedColor="cursor-pointer flex-shrink-0 text-white w-[18px] h-[18px]"
            unlikedColor="cursor-pointer flex-shrink-0 text-grey w-[18px] h-[18px]"
          />
          <IconMore className={clsx("cursor-pointer flex-shrink-0 w-[18px] h-[18px] text-grey", showActionMenu ? "pointer-events-none" : "")} onClick={() => setShowActionMenu(true)} />
          {showActionMenu && (
            <div ref={containerRef} className="absolute top-5 right-0 z-50">
              <ActionMenu data={data} setShowActionMenu={setShowActionMenu} setShowRenameModal={setShowRenameModal} setShowDeleteModal={setShowDeleteModal} imageError={imageError} />
            </div>
          )}
        </div>
        <div
          className={clsx("cursor-pointer pointer-events-none relative h-full aspect-square overflow-hidden rounded-[3px]", imageError ? "pointer-events-none" : "pointer-events-auto")}
          onClick={() => navigate(`/new-training/${data.id}`)}
        >
          <LazyImg
            src={data?.img_orj_path !== "" ? data?.img_orj_path : ImageDefault}
            className="aspect-square object-cover object-center overflow-hidden"
            onError={handleImageError}
            fallbackImg={ImageTrainingFallback}
          />
          {data?.status === TrainingStatus.Draft && (
            <div className="absolute flex items-end h-full bottom-0 pb-2.5 px-2.5 w-full bg-black bg-opacity-50">
              <Button
                disabled={disableStartTrainingBtn}
                className="btn-primary w-full"
                onClick={(e) => {
                  e.stopPropagation();
                  if (window.location.pathname === "/") AnalyticsEvent("homepage_mytrainings_start_training", "", "click");
                  else AnalyticsEvent("mytrainings_start_training", "", "click");
                  setDisableStartTrainingBtn(true);
                  onStartTrain(data?.id);
                }}
              >
                <IconPlay className="w-5 h-5" /> Start Training
              </Button>
            </div>
          )}
        </div>

        {data?.status === TrainingStatus.Start && (
          <div className="absolute bottom-0 pb-2.5 px-2.5 w-full">
            <InProgress className="" queueCount={data.created_order} startDate={data.start_date} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainingsItem;
