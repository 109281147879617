import React, { useEffect, useMemo, useRef, useState } from "react";
import DesignContext, { DesignContextType } from "./DesignContext";
import designService from "api/design/design.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Dimensions } from "./components/DesignLeftMenu";
import userService from "api/user/user.service";
import { useAppSelector } from "store";
import { EventDispatchSnackbar } from "router/Router";
import { ScreenType } from "components/WarningSnackbar/WarningSnackbar";
import { isObjectEmpty } from "utils";

interface DesignProviderProps {
  children: React.ReactNode;
}

export enum ToolType {
  brush,
  magic,
  lasso,
  cleanup,
  remove,
  finetune,
}

export enum DesignType {
  design = "design",
  pattern = "pattern",
  mannequinPhotoshoot = "photoshooting_mannequin",
}

export const DEFAULT_BRUSH_WIDTH = 72;
export const MINUMUM_BRUSH_WIDTH = 1;
export const MAX_BRUSH_WIDTH = 150;

const DesignProvider: React.FC<DesignProviderProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAppSelector((state) => state.common);
  const [isLoading, setIsLoading] = useState(false);
  const [alreadyStartedCreating, setAlreadyStartedCreating] = useState(false);
  const [showPatternModal, setShowPatternModal] = useState(false);
  const [images, setImages] = useState([]);
  const [designId, setDesignId] = useState(id ?? "");
  const [selectedTool, setSelectedTool] = useState<any>(false);
  const [selectedDesignType, setSelectedDesignType] = useState<any>(location.state?.designType ?? null);
  const [currentCursor, setCurrentCursor] = useState("");
  const [brushWidth, setBrushWidth] = useState(DEFAULT_BRUSH_WIDTH);
  const [cPushArray, setcPushArray] = useState<any>([]);
  const [cStep, setcStep] = useState(-1);
  const canvasRef = useRef<any>(null);
  const contextRef = useRef<any>(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [clippedImg, setClippedImg] = useState<any>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [scaledPattern, setScaledPattern] = useState<any>({ isLoading: true, path: "", width: 2, height: 2, w: 100, h: 100 });
  const [selectedMaskArray, setSelectedMaskArray] = useState<any>([]);
  const [resetLeftMenu, setResetLeftMenu] = useState(false);
  const [isOnLoadingItem, setIsOnLoadingItem] = useState(false);
  const [imagesInProcess, setImagesInProcess] = useState([]);
  const [addLogoState, setAddLogoState] = useState({ image: "", isActive: false });
  const [selectedDimension, setSelectedDimension] = useState<Dimensions>(Dimensions.portrait);
  const [showGetFeedback, setShowGetFeedback] = useState(false);
  const [isColorPickerActive, setIsColorPickerActive] = useState(false);
  const [pickedColor, setPickedColor] = useState<string>("#000000");
  const magicSelectRef = useRef<any>(null);

  React.useEffect(() => {
    if (!user?.id) return;

    userService
      .getUserDetail({
        user_id: user?.id,
      })
      .then((res) => {
        if (res.responseData.package_detail[0].name.toLowerCase() === "no package") window.dispatchEvent(new CustomEvent(EventDispatchSnackbar, { detail: { type: 402, screen: ScreenType.Design } }));
      });
  }, []);

  useEffect(() => {
    if (id === undefined) return;
    designService.getDesign(id).then((res) => {
      // design doesn`t exist
      if (res.responseData.length === 0) {
        navigate(`/design`, { replace: true });

        return;
      }
      setDesignId(id);

      //add required fields to images

      const trainingIds = !isObjectEmpty(res.responseData.style_prompts) ? res.responseData.style_prompts[0].trains.filter((item: any) => item.train_id !== "prompt") : []; //removing 'prompt' since it is default

      const formattedArray = res.responseData.images.map((item: any) => ({
        ...item,
        designId: res.responseData.design_id,
        prompt: res.responseData.prompt,
        negative_prompt: res.responseData.negative_prompt,
        set_images: res.responseData.set_images,
        prompt_array: res.responseData.prompt_array,
        negative_prompt_array: res.responseData.negative_prompt_array,
        design_type: res.responseData?.design_type,
        fashion_prompt: res.responseData.fashion_prompt,
        fashion_prompt_array: res.responseData.fashion_prompt_array,
        model_prompt: res.responseData.model_prompt,
        model_prompt_array: res.responseData.model_prompt_array,
        background_prompt: res.responseData.background_prompt,
        background_prompt_array: res.responseData.background_prompt_array,
        training_ids: trainingIds,
        seed: res.responseData.seed,
        edit_type: res.responseData?.edit_type,
        image_guidance: res.responseData.image_guidance,
        is_technical: res.responseData.is_technical,
        is_mp4: res.responseData.is_mp4,
      }));
      setImages(formattedArray);
      setSelectedDesignType(res.responseData.design_type);

      const dimension = Number(res.responseData.width) / Number(res.responseData.height);

      if (dimension > 1) setSelectedDimension(Dimensions.horizontal);
      else if (dimension < 1) setSelectedDimension(Dimensions.portrait);
      else setSelectedDimension(Dimensions.sqaure);
    });
  }, [id]);

  const isCanvasDrawn = useMemo(() => {
    // If there's no history, nothing is drawn
    if (cPushArray.length === 0) return false;

    // First drawing is represented by array length 1 and cStep 0
    if (cPushArray.length === 1 && cStep === 0) return true;

    // For subsequent drawings, compare with initial state
    if (cStep >= 0 && cPushArray.length > 1) {
      const initialState = cPushArray[0];
      const currentState = cPushArray[cStep];

      return initialState !== currentState;
    }

    return false;
  }, [cPushArray, cStep]);

  function setToDraw() {
    if (!contextRef.current) return;
    contextRef.current.globalCompositeOperation = "source-over";
  }

  function setToErase() {
    if (!contextRef.current) return;
    contextRef.current.globalCompositeOperation = "destination-out";
  }

  function redrawLatestCanvasState() {
    if (!contextRef.current || !canvasRef.current || cPushArray.length === 0) return;

    const currentStatus = contextRef.current.globalCompositeOperation;
    const previousGlobalAlpha = contextRef.current.globalAlpha;
    contextRef.current.globalAlpha = 1.0;

    const canvasPic = new Image();
    canvasPic.onload = function () {
      contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      contextRef.current.globalCompositeOperation = "source-over";
      contextRef.current.drawImage(canvasPic, 0, 0, canvasPic.width, canvasPic.height);
      contextRef.current.globalCompositeOperation = currentStatus;
      contextRef.current.globalAlpha = previousGlobalAlpha;
    };
    canvasPic.src = cPushArray[cStep];
  }

  function push(resetHistory?: boolean) {
    if (!canvasRef.current) return;

    if (resetHistory) {
      const newArray = [canvasRef.current.toDataURL()];
      setcPushArray(newArray);
      setcStep(0);

      return;
    }

    // If we're not at the end of the history, truncate the future states
    if (cStep < cPushArray.length - 1) {
      setcPushArray((prev: any) => prev.slice(0, cStep + 1));
    }

    // Add new state and increment step
    const newState = canvasRef.current.toDataURL();
    setcPushArray((prev: any) => [...prev, newState]);
    setcStep((prev) => prev + 1);
  }

  function undo() {
    // Only return if no context or no history at all
    if (!contextRef.current || cPushArray.length === 0) return;
    if (!canvasRef.current) return;

    const currentStatus = contextRef.current.globalCompositeOperation;
    const offScreenCanvas = document.createElement("canvas");
    const offScreenContext = offScreenCanvas.getContext("2d");

    // Set up off-screen canvas with the same size as the main canvas
    offScreenCanvas.width = canvasRef.current.width;
    offScreenCanvas.height = canvasRef.current.height;

    if (!offScreenContext) return;

    // Reset globalAlpha to 1.0 to avoid compounding transparency
    const previousGlobalAlpha = contextRef.current.globalAlpha;
    contextRef.current.globalAlpha = 1.0;

    // If we're going back to clean canvas (cStep would become -1)
    if (cStep === 0) {
      contextRef.current.clearRect(0, 0, contextRef.current.canvas.width, contextRef.current.canvas.height);
      contextRef.current.globalAlpha = previousGlobalAlpha;
      setcStep(-1);

      return;
    }

    const canvasPic = new Image();
    canvasPic.onload = function () {
      // Draw the previous step on the off-screen canvas
      offScreenContext.drawImage(canvasPic, 0, 0, canvasPic.width, canvasPic.height, 0, 0, offScreenCanvas.width, offScreenCanvas.height);

      // Clear the main canvas and copy from the off-screen canvas
      contextRef.current.clearRect(0, 0, contextRef.current.canvas.width, contextRef.current.canvas.height);
      contextRef.current.globalCompositeOperation = "source-over";
      contextRef.current.drawImage(offScreenCanvas, 0, 0, offScreenCanvas.width, offScreenCanvas.height);
      contextRef.current.globalCompositeOperation = currentStatus;

      // Restore the previous globalAlpha after drawing
      contextRef.current.globalAlpha = previousGlobalAlpha;

      // Update the step count
      setcStep(cStep - 1);
    };
    canvasPic.src = cPushArray[cStep - 1];
  }

  function resetCanvas() {
    if (!contextRef.current || !canvasRef.current) return;
    contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    push(true);
  }

  function redo() {
    if (!contextRef.current || cStep >= cPushArray.length - 1) return;
    if (!canvasRef.current) return;

    const currentStatus = contextRef.current.globalCompositeOperation;
    const offScreenCanvas = document.createElement("canvas");
    const offScreenContext = offScreenCanvas.getContext("2d");

    if (!offScreenContext) return;

    // Reset globalAlpha to 1.0 to avoid compounding transparency
    const previousGlobalAlpha = contextRef.current.globalAlpha;
    contextRef.current.globalAlpha = 1.0;

    // Set up off-screen canvas with the same size as the main canvas
    offScreenCanvas.width = canvasRef.current.width;
    offScreenCanvas.height = canvasRef.current.height;

    const canvasPic = new Image();
    canvasPic.onload = function () {
      // Draw the next step on the off-screen canvas
      offScreenContext.drawImage(canvasPic, 0, 0, canvasPic.width, canvasPic.height, 0, 0, offScreenCanvas.width, offScreenCanvas.height);

      // Clear the main canvas and copy from the off-screen canvas
      contextRef.current.clearRect(0, 0, contextRef.current.canvas.width, contextRef.current.canvas.height);
      contextRef.current.globalCompositeOperation = "source-over";
      contextRef.current.drawImage(offScreenCanvas, 0, 0, offScreenCanvas.width, offScreenCanvas.height);
      contextRef.current.globalCompositeOperation = currentStatus;

      // Restore the previous globalAlpha after drawing
      contextRef.current.globalAlpha = previousGlobalAlpha;

      // Update the step count
      setcStep(cStep + 1);
    };
    canvasPic.src = cPushArray[cStep + 1];
  }

  const contextValue: DesignContextType = {
    selectedDesignType,
    setSelectedDesignType,
    selectedTool,
    setSelectedTool,
    currentCursor,
    setCurrentCursor,
    brushWidth,
    setBrushWidth,
    canvasRef,
    contextRef,
    setToDraw,
    setToErase,
    redo,
    undo,
    push,
    setDesignId,
    designId,
    images,
    setImages,
    selectedImageIndex,
    setSelectedImageIndex,
    cPushArray,
    clippedImg,
    setClippedImg,
    isLoading,
    setIsLoading,
    alreadyStartedCreating,
    setAlreadyStartedCreating,
    showPatternModal,
    setShowPatternModal,
    isCanvasDrawn,
    cStep,
    isDeleting,
    setIsDeleting,
    scaledPattern,
    setScaledPattern,
    selectedMaskArray,
    setSelectedMaskArray,
    resetLeftMenu,
    setResetLeftMenu,
    isOnLoadingItem,
    setIsOnLoadingItem,
    imagesInProcess,
    setImagesInProcess,
    addLogoState,
    setAddLogoState,
    selectedDimension,
    setSelectedDimension,
    showGetFeedback,
    setShowGetFeedback,
    magicSelectRef,
    isColorPickerActive,
    setIsColorPickerActive,
    pickedColor,
    setPickedColor,
    redrawLatestCanvasState,
    resetCanvas,
  };

  return <DesignContext.Provider value={contextValue}>{children}</DesignContext.Provider>;
};

export default DesignProvider;
