import { RefabricProURL } from "../index";
import { ApiResponse } from "../HttpClient";
import { ITrainCreateRequest, ITrainCreateImagesRequest, ITrainEditRequest, ITrainEditImageRequest, ITrainAddTrainingRequest } from "./train.type";

export default {
  async getTrain(train_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`trains/${train_id}`, {});
  },
  async getTrains(params: { limit: number; page?: number; query?: string; order?: any; is_like?: any }): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`trains/`, { params });
  },
  async getCreatedTrains(params: { limit: number; page?: number; query?: string; order?: any; is_like?: any; train_type?: number }): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`trains/created`, { params });
  },
  async getTrendTrains(params: { limit: number; page?: number; query?: string; order?: any; is_like?: any }): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`trains/trends`, { params });
  },
  async createTrain(data: ITrainCreateRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post("trains/", data, {});
  },
  async startTrain(train_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`trains/${train_id}/start`, {});
  },
  async restartTrain(train_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`trains/${train_id}/restart`, {});
  },
  async deleteTrain(train_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.delete(`trains/${train_id}`, {});
  },
  async editTrain(train_id: string, data: ITrainEditRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.put(`trains/${train_id}`, data, {});
  },
  async addTraintoTrain(train_id: string, data: ITrainAddTrainingRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`trains/${train_id}/add_train`, data, {});
  },

  /*******      TRAIN IMAGES      *******/
  async getTrainImages(train_id: string, params: { limit: number; page?: number; query?: string }): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`trains/${train_id}/images`, { params });
  },
  async createTrainImages(data: ITrainCreateImagesRequest, options?: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`trains/${data.train_id}/images`, data, options);
  },
  async getTrainImage(train_id: string, image_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`trains/${train_id}/images${image_id}`, {});
  },
  async deleteTrainImage(train_id: string, image_id: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.delete(`trains/${train_id}/images/${image_id}`, {});
  },
  async editTrainImage(data: ITrainEditImageRequest): Promise<ApiResponse<any>> {
    return await RefabricProURL.put(`trains/${data.train_id}/images/${data.image_id}`, data, {});
  },
};
