import React from "react";
import collectionsService from "api/collections/collections.service";
import clsx from "clsx";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import { IconBookmarkAdded, IconCheck, IconPlus } from "icons";
import { useDispatch } from "react-redux";
import { setSnackbar } from "store/commonSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import designService from "api/design/design.service";

const schema = yup
  .object({
    collectionName: yup.string().required("Please enter a name!"),
  })
  .required();

const CreateNewCollection = ({ designId, imageId, onClose }: any) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onValid = async (data: any) => {
    collectionsService
      .createCollection({
        name: data.collectionName,
        directory_id: 0,
      })
      .then((res) => {
        dispatch(setSnackbar({ message: "New collection successfully created!", icon: IconCheck }));
        designService
          .saveToCollection(designId, imageId, {
            design_id: designId,
            image_id: imageId,
            collection_id: res.responseData.collection_id,
          })
          .then(() => {
            onClose();
            dispatch(
              setSnackbar({
                icon: IconBookmarkAdded,
                message: "Image successfully saved.",
              })
            );
          });
      });
  };

  const onHandleSubmit = () => {
    handleSubmit(
      (data) => onValid(data),
      (e) => console.log(e)
    )();
  };

  return (
    <div className={clsx("flex flex-col border border-black-300 rounded-t-[5px] rounded-b-[5px] bg-black-100")}>
      <div className="flex bg-black-300 p-2 gap-[5px] text-white text-headline uppercase">
        <IconPlus className="w-[14px] h-[14px]" />
        create new collection
      </div>
      <div className="flex flex-col p-2.5 gap-4">
        <div className="flex flex-col gap-2.5">
          <span className="text-h7 text-white">Collection Name*</span>
          <Input {...register("collectionName")} error={errors.collectionName?.message} />
        </div>
      </div>
      <div className="flex self-end p-2.5 gap-2.5 w-full border-t border-black-300 rounded-b-[5px]">
        <Button className="btn-secondary w-full" onClick={onClose}>
          Cancel
        </Button>
        <Button className="btn-primary w-full" onClick={onHandleSubmit}>
          Create
        </Button>
      </div>
    </div>
  );
};

export default CreateNewCollection;
