export const IMAGE_SIZE_LIMIT = 5;
export const CLIENT_ID_LOCAL_STORAGE_KEY = "refabric_pro_client_id";
export const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const MIN_WIDTH_HEIGHT_DEFAULT = 1024; // Upload default
export const MIN_WIDTH_HEIGHT_REFERENCE_IMAGE = 512; // Reference Image
export const MIN_WIDTH_HEIGHT_PROMPT_ASSISTANT_MESSAGE = 0; // Prompt Assistant Message
export const MIN_WIDTH_HEIGHT_EDIT_FABRIC_AND_PRINT = 512; // Edit Fabric and Print
export const MIN_WIDTH_HEIGHT_REFERENCE_FABRIC = 512; // Reference Fabric
export const MIN_WIDTH_HEIGHT_REFERENCE_SKETCH = 0; // Reference Sketch
export const MIN_WIDTH_HEIGHT_ADD_LOGO = 0; // Add Logo
export const MIN_WIDTH_HEIGHT_MARKETPLACE_CONTENT = 0; // Marketplace Content
export const MIN_WIDTH_HEIGHT_TRYON_IMAGE_SELECT = 512; // Tryon Image Select

export const MIN_WIDTH_GHOST_IMAGE = 768; // Ghost Image
export const MIN_HEIGHT_GHOST_IMAGE = 1024; // Ghost Image
export const MIN_WIDTH_MANNEQUIN_IMAGE = 768; // Mannequin Image
export const MIN_HEIGHT_MANNEQUIN_IMAGE = 1024; // Mannequin Image
