import React from "react";
import Modal from "components/Modal";
import { IconBackArrow } from "icons";

const ImageCarousel = ({ images, currentIndex, show, onClose }: any) => {
  const [selectedIndex, setSelectedIndex] = React.useState(currentIndex);

  React.useEffect(() => {
    setSelectedIndex(currentIndex);
  }, [currentIndex]);

  React.useEffect(() => {
    const handleEscapeKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscapeKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, []);

  return (
    <Modal className="!z-[9999] !bg-opacity-70" bodyClassName="!border-none !rounded-none flex h-full py-12" onClose={onClose} show={show}>
      <div className="flex items-center h-full w-fit gap-16">
        <button
          className="bg-white rounded-full p-1.5"
          onClick={() => {
            setSelectedIndex(selectedIndex === 0 ? images.length - 1 : selectedIndex - 1);
          }}
        >
          <IconBackArrow className="text-black" />
        </button>
        <div className="flex h-full gap-2.5">
          <img src={images[selectedIndex]} className="object-contain h-full rounded-[10px] max-w-[80vw]" draggable={false} />
        </div>
        <button
          className="bg-white rounded-full p-1.5"
          onClick={() => {
            setSelectedIndex(selectedIndex === images.length - 1 ? 0 : selectedIndex + 1);
          }}
        >
          <IconBackArrow className="text-black rotate-180" />
        </button>
      </div>
    </Modal>
  );
};

export default ImageCarousel;
