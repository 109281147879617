import React, { useState, useRef } from "react";
import clsx from "clsx";
import { useClickOutside } from "hooks/useClickOutside";
import { useTheme, Theme } from "hooks/useTheme";
import { IconCheck, IconChevronDown, IconContrast, IconDark, IconDesktop, IconLight } from "icons";

interface ThemeOption {
  id: Theme;
  icon: React.ReactNode;
  label: string;
}

interface Props {
  className?: string;
}

const ThemeToggle: React.FC<Props> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { theme, setTheme } = useTheme();

  useClickOutside(menuRef, () => setIsOpen(false));

  const themeOptions: ThemeOption[] = [
    {
      id: "dark",
      icon: <IconDark />,
      label: "Dark",
    },
    {
      id: "light",
      icon: <IconLight />,
      label: "Light",
    },
    {
      id: "system",
      icon: <IconDesktop />,
      label: `System`,
    },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const selectTheme = (themeId: Theme) => {
    setTheme(themeId);
    setIsOpen(false);
  };

  const selectedOption = themeOptions.find((option) => option.id === theme);

  return (
    <div className={clsx("relative", className)} ref={menuRef}>
      <button
        onClick={toggleMenu}
        className="flex items-center w-full gap-3 py-2.5 px-2 rounded-md bg-black-300 text-blackGreen hover:bg-black-200 transition-colors"
        aria-label="Toggle theme menu"
        aria-expanded={isOpen}
      >
        <IconContrast className="w-[18px] h-[18px]" />
        <span className="text-[14px]">Theme: {selectedOption?.label || "System"}</span>
        <IconChevronDown className={clsx("text-grey w-[18px] h-[18px] ml-auto transition-transform", isOpen ? "rotate-90" : "-rotate-90")} />
      </button>

      {isOpen && (
        <div className="absolute bottom-0 flex flex-col gap-1 left-full ml-5 w-[198px] bg-black-200 rounded-lg overflow-hidden p-1.5 z-[9999]">
          {themeOptions.map((option) => (
            <button
              key={option.id}
              className={clsx("flex items-center w-full px-2 py-2.5 hover:bg-black-300 text-left rounded transition-colors", theme === option.id ? "text-blackGreen bg-black-300" : "text-grey")}
              onClick={() => selectTheme(option.id)}
              aria-label={`Select ${option.label} theme`}
            >
              <span className="mr-4">{option.icon}</span>
              <span>{option.label}</span>
              {theme === option.id && <IconCheck className="ml-auto w-[18px] h-[18px]" />}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ThemeToggle;
