import React, { useEffect, useRef, useState } from "react";
import Button from "components/Button/Button";
import { IconAddImage, IconAnalyze, IconAnalyzePrompt, IconAssitant, IconClose, IconCreate, IconGenerate, IconImageDescribe, IconRefresh, IconSend } from "icons";
import Input from "components/Input/Input";
import Message from "./components/Message";
import promptassistantService from "api/prompt-assistant/promptassistant.service";
import { AnalyticsEvent } from "utils";

const initialMessage = [{ from: 0, message: "Hi there 👋 Do you need help?", imageUpload: false, element: null }];

const PromptAssistant = ({ showChatBox, setShowChatBox, handleCallback }: { showChatBox: boolean; setShowChatBox: any; handleCallback?: (text: string) => void }) => {
  const [showUserChoices, setShowUserChoices] = useState(true);
  const [showInputField, setShowInputField] = useState(false);
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [messages, setMessages] = useState<any>([{ from: 0, message: "Hi there 👋 Do you need help?", imageUpload: false, uploadedImageURL: "", element: null }]);
  const [isBotWorking, setIsBotWorking] = useState(false);
  const chatContainer = useRef<HTMLInputElement>(null);
  const inputPrompt = useRef<HTMLInputElement>(null);
  const [isChatBoxAlreadyOpened, setIsChatBoxAlreadyOpened] = useState(false);

  useEffect(() => {
    if (!showChatBox || isChatBoxAlreadyOpened) return;
    if (showChatBox) setIsChatBoxAlreadyOpened(true);
  }, [showChatBox]);

  function scrollBottom() {
    if (chatContainer.current) {
      chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
    }
  }

  useEffect(() => {
    scrollBottom();
  }, [messages, showUserChoices, isBotWorking, showRefreshButton]);

  useEffect(() => {
    scrollBottom();
  }, [showChatBox]);

  const UserChoices = () => (
    <div className="flex flex-col gap-2 ml-[42px]">
      <Button
        className="btn-secondary w-[260px] justify-start text-h7 btn-sm rounded"
        onClick={() => {
          AnalyticsEvent("prompt_assistant_describe_image", "", "click");
          setMessages((prev: any) => [
            ...prev,
            { from: 1, message: "Create a prompt with my image", imageUpload: false },
            { from: 0, message: "If you could upload a photo similar to the output you want I can create a prompt for you!", imageUpload: false },
            { from: 0, message: "", imageUpload: true },
          ]);
          setShowUserChoices(false);
        }}
      >
        <IconImageDescribe className="w-[18px] h-[18px]" />
        Describe my image as a prompt
      </Button>
      <Button
        className="btn-secondary w-[260px] justify-start text-h7 btn-sm rounded"
        onClick={() => {
          AnalyticsEvent("prompt_assistant_beautfy_prompt", "", "click");
          setMessages((prev: any) => [
            ...prev,
            { from: 1, message: "Help me beautify my prompt", imageUpload: false },
            { from: 0, message: "Write the prompt you want me to beautify", imageUpload: false },
          ]);
          setShowUserChoices(false);
          setShowInputField(true);
          inputPrompt.current?.focus();
        }}
      >
        <IconCreate className="w-[18px] h-[18px]" />
        Help me beautify my prompt
      </Button>
      {/* <Button className="btn-secondary w-[260px] justify-start text-h7 btn-sm rounded">
        <IconAnalyzePrompt className="w-[18px] h-[18px]" />
        Analyze my prompt
      </Button> */}
    </div>
  );

  const RefrehshButton = () => (
    <div className="flex self-end">
      <Button
        className="btn-primary"
        onClick={() => {
          setMessages(initialMessage);
          setShowUserChoices(true);
          setShowInputField(false);
          setShowRefreshButton(false);
          setIsBotWorking(false);
        }}
      >
        <IconRefresh className="w-5 h-5" />
        Refresh the Page
      </Button>
    </div>
  );

  function handleInput() {
    setShowUserChoices(false);
    if (inputPrompt.current) {
      const _prompt = inputPrompt.current.value;
      setMessages((prev: any) => [...prev, { from: 1, message: _prompt, imageUpload: false }]);
      setIsBotWorking(true);
      setShowInputField(false);
      promptassistantService
        .describePrompt(_prompt)
        .then((res) => {
          setMessages((prev: any) => [...prev, { from: 0, message: res.data, imageUpload: false }, { copyPrompt: res.data }]);
          setIsBotWorking(false);
          setShowUserChoices(true);
        })
        .catch(() => {
          setIsBotWorking(false);
          setMessages((prev: any) => [...prev, { from: 0, error: true, message: "Something went wrong. Please refresh the page!", imageUpload: false, element: null }]);
          setShowRefreshButton(true);
        });
    }
  }
  function handleClose() {
    setShowChatBox(false);
  }

  return showChatBox ? (
    <div className="flex flex-col gap-2 items-end h-full">
      <div className="flex flex-col gap-[15px] bg-black-100 w-[480px] rounded-xl border border-black-300 h-full">
        <div className="flex justify-between items-center text-headline uppercase text-white w-full bg-black-300 rounded-t-[11px] p-2">
          <span className="">Prompt Assistant</span>
          <div className="cursor-pointer flex-center  w-7 h-7 rounded-full" onClick={handleClose}>
            <IconClose className="w-3 h-3 text-white" />
          </div>
        </div>
        <div className="flex flex-col h-full overflow-y-scroll gap-4 px-4 pb-4 scroll-smooth no-scrollbar" ref={chatContainer}>
          {messages.map((item: any, idx: number) => (
            <Message
              key={`msg${idx}`}
              messages={messages}
              src={item?.src}
              from={item.from}
              message={item.message}
              copyItems={item?.copyItems}
              copyPrompt={item?.copyPrompt}
              imageUpload={item.imageUpload}
              setMessages={setMessages}
              setShowUserChoices={setShowUserChoices}
              setIsBotWorking={setIsBotWorking}
              setShowRefreshButton={setShowRefreshButton}
              error={item?.error}
              handleCallback={handleCallback}
            />
          ))}
          {isBotWorking && (
            <div className="flex items-center gap-2.5">
              <div className="flex-center w-[34px] h-[34px] bg-white rounded-full border border-border">
                <IconAssitant className=" w-[14px] h-[14px]" />
              </div>
              <div className="flex-center w-8 h-2">
                <div className="dot-flashing"></div>
              </div>
            </div>
          )}
          {showUserChoices && <UserChoices />}
          {showRefreshButton && <RefrehshButton />}
        </div>
        {showInputField && (
          <Input
            type="text"
            ref={inputPrompt}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                handleInput();
              }
            }}
            placeholder="Write your prompt here..."
            className="text-bodySm !border-none"
            containerClassName="w-full h-[46px] border-none !bg-black rounded-t-none !rounded-b-[10px]"
            icon={
              <div className="cursor-pointer flex-shrink-0 flex-center h-7 w-7 rounded bg-white" onClick={handleInput}>
                <IconSend className="w-4 h-4" />
              </div>
            }
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PromptAssistant;
