import { PATHS } from "router/config/paths";
import beepSound from "../sound/beep.mp3";

interface ImageCheckResult {
  isValid: boolean;
  width?: number;
  height?: number;
}

export const clipboardCopy = (text: string) => {
  try {
    navigator.clipboard.writeText(text);
  } catch {
    window.navigator["clipboard"].writeText(text);
  }
};

export const downloadImages = (imageURLs: string[]) => {
  imageURLs.forEach((imageURL, index) => {
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = `image${index}.png`;
    link.click();
  });
};

export function isObjectEqual(obj1: any, obj2: any): boolean {
  // If the types are different, they are not equal
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // If they are primitive values, compare them using strict equality
  if (typeof obj1 !== "object" || obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  // Compare the properties of the objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!isObjectEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export const isObjectEmpty = (object: any) => Object.keys(object).length === 0;

enum DateFormat {
  NUMERIC = 0,
  SHORT = 1,
}

export function formatDate(inputDate: string | Date, formatType: DateFormat = 0): string {
  const date = new Date(inputDate);

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  switch (formatType) {
    case DateFormat.NUMERIC:
      return `${(date.getMonth() + 1).toString().padStart(2, "0")}.${date.getDate().toString().padStart(2, "0")}.${date.getFullYear()} ${date.getHours().toString().padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    case DateFormat.SHORT: {
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "short",
        day: "2-digit",
      };
      const formattedDate = date.toLocaleDateString("en-US", options);
      const [month, day, year] = formattedDate.split(" ");

      return `${month} ${day} ${year}`;
    }
    default:
      return "Invalid Format Type";
  }
}

export function playSound(sound = beepSound) {
  const audio = new Audio(sound);
  audio.play();
}

import ReactGA from "react-ga4";

export function AnalyticsEvent(event_name: string, category: string, action: string, params?: any) {
  ReactGA.event(event_name, {
    category: category,
    action: action,
    ...params,
  });
}

export function getClearPath(path: PATHS) {
  // Split the path at the colon and take the first part
  let clearPath = path.split(":")[0];
  // Remove any trailing slash if present
  if (clearPath.endsWith("/")) {
    clearPath = clearPath.slice(0, -1);
  }

  return clearPath;
}

export function removeBackslash(path: string) {
  return path.replace(/\/$/, "");
}

export const getSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const params: { [key: string]: string } = {};

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};

export const checkImage = (url?: string): Promise<ImageCheckResult> => {
  if (!url) return Promise.resolve({ isValid: false });

  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      resolve({
        isValid: true,
        width: img.width,
        height: img.height,
      });
    };

    img.onerror = () => {
      resolve({ isValid: false });
    };

    img.src = url;
  });
};

export const checkMultipleImages = async (urls: string[]): Promise<{ [key: string]: ImageCheckResult }> => {
  const results = await Promise.all(
    urls.map(async (url) => ({
      url,
      result: await checkImage(url),
    }))
  );

  return results.reduce(
    (acc, { url, result }) => ({
      ...acc,
      [url]: result,
    }),
    {}
  );
};

export const formatSize = (size: number) => {
  if (size < 1024) {
    return `${size} B`;
  }

  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  }

  return `${(size / (1024 * 1024)).toFixed(2)} MB`;
};
