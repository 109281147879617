import React, { useEffect, useRef, useState } from "react";
import { IconClose } from "icons";
import { useDispatch } from "react-redux";
import { resetSnackbar } from "store/commonSlice";
import { useTheme } from "hooks/useTheme";

const colors = ["#D1EF70", "red", "#E88053"];

const Snackbar = ({ data, isLastItem }: any) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const Icon = data.icon;
  const snackbarRef = useRef<HTMLDivElement | null>(null);
  const [mouseEntered, setMouseEntered] = useState(false);
  const { theme } = useTheme();
  const isDark = theme === "dark";
  console.log(isDark);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        if (!mouseEntered) {
          setShow(false);
          if (isLastItem) dispatch(resetSnackbar());
        }
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [show, mouseEntered]);

  return (
    <div
      ref={snackbarRef}
      className={`flex flex-col w-fit bg-black text-h6 text-white rounded-[4px] border border-black-300 ${show ? "opacity-100" : "opacity-0"} transition-opacity duration-700`}
      style={{
        borderColor: colors[data.type] ?? "#2B2B2B",
      }}
    >
      <div className="flex items-center gap-2.5 p-2 ">
        {Icon && <Icon className="w-[14px] h-[14px]" style={{ color: colors[data.type] ? colors[data.type] : isDark ? "white" : "black" }} />}
        <span className="text-bodySm" style={{ color: colors[data.type] ? colors[data.type] : isDark ? "white" : "black" }}>
          {data.message}
        </span>
        <IconClose className="flex-shrink-0  cursor-pointer w-[14px] h-[14px] text-grey" onClick={() => setShow(false)} />
      </div>
      <div className="h-[1px] rounded-b-full bg-white animate-snackbar" style={{ backgroundColor: colors[data.type] ? colors[data.type] : isDark ? "white" : "black" }}></div>
    </div>
  );
};

export default Snackbar;
