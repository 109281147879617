import React, { useEffect, useRef, useState } from "react";
import Accordion from "components/Accordion/Accordion";
import {
  IconAssitant,
  IconCamera,
  IconDraw,
  IconFullBody,
  IconCloseUp,
  IconLandscape,
  IconPattern,
  IconPortrait,
  IconPose,
  IconSettings,
  IconSketch,
  IconSquare,
  IconStyle,
  IconTrain,
  IconUpload,
  IconPortrait1,
  IconRightArrow,
  IconModel,
  IconRemoveBg,
  IconHelp,
  IconFabric,
  IconInspiration,
  IconVariation,
  IconLevel1,
  IconLevel2,
  IconLevel3,
  IconStandard,
  Icon2x,
  IconTip,
} from "icons";
import Button from "components/Button/Button";
import Tab from "components/Tab/Tab";
import Textarea from "components/Textarea/Textarea";
import clsx from "clsx";
import { useDesignContext } from "../DesignContext";
import trainService from "api/train/train.service";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import designService from "api/design/design.service";
import UploadBox from "components/UploadBox";
import { DesignType } from "../DesignProvider";
import { AnalyticsEvent, playSound } from "utils";
import { useNavigate } from "react-router-dom";
import staticsService from "api/statics/statics.service";
import PromptAssistant from "components/PromptAssistant";
import { useClickOutside } from "hooks/useClickOutside";
import TrainingSelect from "./TrainingSelect";
import Tooltip from "components/Tooltip";
import { useDispatch } from "react-redux";
import { setIsDesignTutorialCompleted, setSnackbar } from "store/commonSlice";
import { useAppSelector } from "store";
import useS3 from "hooks/useS3";
import sketchImage from "images/sketch-example.png";
import PlusAI from "components/PlusAI";
import { TUTORIAL_DESIGN_COMPLETED, useLocalStorage } from "hooks/useLocalStorage";
import {
  ImageExampleReference1,
  ImageExampleReference10,
  ImageExampleReference11,
  ImageExampleReference12,
  ImageExampleReference13,
  ImageExampleReference14,
  ImageExampleReference15,
  ImageExampleReference16,
  ImageExampleReference17,
  ImageExampleReference18,
  ImageExampleReference19,
  ImageExampleReference2,
  ImageExampleReference20,
  ImageExampleReference3,
  ImageExampleReference4,
  ImageExampleReference5,
  ImageExampleReference6,
  ImageExampleReference7,
  ImageExampleReference8,
  ImageExampleReference9,
} from "images";
import ImageCarousel from "components/ImageCarousel";
import CheckBox from "components/CheckBox";
import AnimatedGradientBorder from "./AnimatedGradientBorder";
import { MIN_WIDTH_HEIGHT_REFERENCE_FABRIC, MIN_WIDTH_HEIGHT_REFERENCE_IMAGE, MIN_WIDTH_HEIGHT_REFERENCE_SKETCH } from "utils/constants";

export const MAX_SELECTABLE_TRAINING_COUNT = 1;

export enum ReferenceImageType {
  Image,
  Sketch,
  Pose,
}

export enum FocusType {
  FullBody,
  CloseUp,
  Portrait,
}

export enum GuidanceScaleType {
  Inspiration = "weak",
  Variation = "strong",
}

export enum TrainingEffectLevel {
  Low = "low",
  Medium = "medium",
  High = "high",
}

const schema = yup
  .object({
    fashion_prompt: yup.string().when(["imageReference"], {
      is: "",
      then() {
        return yup.string().required("Please enter a prompt!");
      },
      otherwise() {
        return yup.string();
      },
    }),
    model_prompt: yup.string(),
    background_prompt: yup.string(),
    negativePrompt: yup.string(),
    imageReference: yup.string(),
    imagePose: yup.string(),
    imageSketch: yup.string(),
    imageFabric: yup.string(),
    focusType: yup.string(),
    imageGuidance: yup.string(),
    sketchGuidance: yup.string(),
    trainingEffectLevel: yup.string(),
    imageOutput: yup.number(),
    generationMode: yup.string(),
  })
  .required();

export enum Dimensions {
  horizontal = "768_512",
  sqaure = "512_512",
  portrait = "512_768",
}

const DimensionSelection = ({ selectedDimension, selectedDesignType, setSelectedDimension, disabled }: any) => (
  <div
    className="flex flex-col gap-2"
    style={{
      pointerEvents: disabled ? "none" : "auto",
    }}
  >
    <span className="text-h7 text-white">Dimensions</span>
    {selectedDesignType === DesignType.design && (
      <Tab
        initTab={selectedDesignType === DesignType.pattern ? Dimensions.sqaure : selectedDimension}
        onChange={(value: any) => {
          AnalyticsEvent(`design_screen_select_dimension_${value}`, "", "click");
          setSelectedDimension(value);
        }}
      >
        <Tab.Item disabled={selectedDesignType === DesignType.pattern} id={Dimensions.horizontal}>
          <div className="flex-center gap-[5px] w-full">
            <IconLandscape className="w-[18px] h-[18px]" /> 16:9
          </div>
        </Tab.Item>
        <Tab.Item id={Dimensions.sqaure}>
          <div className="flex-center gap-[5px] w-full">
            <IconSquare className="w-[18px] h-[18px]" />
            1:1
          </div>
        </Tab.Item>
        <Tab.Item disabled={selectedDesignType === DesignType.pattern} id={Dimensions.portrait}>
          <div className="flex-center gap-[5px] w-full">
            <IconPortrait className="w-[18px] h-[18px]" />
            9:16
          </div>
        </Tab.Item>
      </Tab>
    )}
  </div>
);

const ImageOutputCounts = [1, 2, 4];

const GenerationModes = [
  { text: "Standard", value: "standard", icon: IconStandard },
  { text: "Enhanced", value: "enhanced", icon: Icon2x },
];

const ReferenceInspirationExamples = () => {
  const exampleImages = [ImageExampleReference1, ImageExampleReference2, ImageExampleReference3, ImageExampleReference4, ImageExampleReference5];

  const [selectedImage, setSelectedImage] = useState<any>(null);

  return (
    <div className="flex flex-col border border-black-300 rounded-[5px]">
      <ImageCarousel
        show={selectedImage !== null}
        images={exampleImages}
        currentIndex={selectedImage}
        onClose={() => {
          setSelectedImage(null);
        }}
      />

      <div className="flex items-center gap-[5px] p-2 text-white bg-black-300 rounded rounded-b-none">
        <IconTip className="w-[14px] h-[14px]" />
        <span className="text-headline uppercase">Inspiration Examples</span>
      </div>
      <div className="flex flex-col gap-2 bg-black rounded-b p-3">
        <span className="text-bodySm text-white">Upload an image that you want the design to be inspired by.</span>

        <div className="flex items-center gap-2">
          {exampleImages.map((item, index) => (
            <img
              key={"ImageReferenceExamples_" + index}
              src={item}
              className="cursor-pointer w-[50px] h-[50px] rounded-sm"
              onClick={() => {
                setSelectedImage(index);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const ReferenceVariationExamples = () => {
  const exampleImages = [ImageExampleReference6, ImageExampleReference7, ImageExampleReference8, ImageExampleReference9, ImageExampleReference10];

  const [selectedImage, setSelectedImage] = useState<any>(null);

  return (
    <div className="flex flex-col border border-black-300 rounded-[5px]">
      <ImageCarousel
        show={selectedImage !== null}
        images={exampleImages}
        currentIndex={selectedImage}
        onClose={() => {
          setSelectedImage(null);
        }}
      />

      <div className="flex items-center gap-[5px] p-2 text-white bg-black-300 rounded rounded-b-none">
        <IconTip className="w-[14px] h-[14px]" />
        <span className="text-headline uppercase">Variation Examples</span>
      </div>
      <div className="flex flex-col gap-2 bg-black rounded-b p-3">
        <span className="text-bodySm text-white">Upload an image that you want the design to be a variation of.</span>

        <div className="flex items-center gap-2">
          {exampleImages.map((item, index) => (
            <img
              key={"ImageReferenceExamples_" + index}
              src={item}
              className="cursor-pointer w-[50px] h-[50px] rounded-sm"
              onClick={() => {
                setSelectedImage(index);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const ReferenceSketchExamples = () => {
  const exampleImages = [ImageExampleReference11, ImageExampleReference12, ImageExampleReference13, ImageExampleReference14, ImageExampleReference15];

  const [selectedImage, setSelectedImage] = useState<any>(null);

  return (
    <div className="flex flex-col border border-black-300 rounded-[5px]">
      <ImageCarousel
        show={selectedImage !== null}
        images={exampleImages}
        currentIndex={selectedImage}
        onClose={() => {
          setSelectedImage(null);
        }}
      />

      <div className="flex items-center gap-[5px] p-2 text-white bg-black-300 rounded rounded-b-none">
        <IconTip className="w-[14px] h-[14px]" />
        <span className="text-headline uppercase">Sketch Examples</span>
      </div>
      <div className="flex flex-col gap-2 bg-black rounded-b p-3">
        <span className="text-bodySm text-white">Upload a sketch or technical drawing image to turn it into realistic shot.</span>

        <div className="flex items-center gap-2">
          {exampleImages.map((item, index) => (
            <img
              key={"ImageReferenceExamples_" + index}
              src={item}
              className="cursor-pointer w-[50px] h-[50px] rounded-sm"
              onClick={() => {
                setSelectedImage(index);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const ReferencePoseExamples = () => {
  const exampleImages = [ImageExampleReference16, ImageExampleReference17, ImageExampleReference18, ImageExampleReference19, ImageExampleReference20];

  const [selectedImage, setSelectedImage] = useState<any>(null);

  return (
    <div className="flex flex-col border border-black-300 rounded-[5px]">
      <ImageCarousel
        show={selectedImage !== null}
        images={exampleImages}
        currentIndex={selectedImage}
        onClose={() => {
          setSelectedImage(null);
        }}
      />

      <div className="flex items-center gap-[5px] p-2 text-white bg-black-300 rounded rounded-b-none">
        <IconTip className="w-[14px] h-[14px]" />
        <span className="text-headline uppercase">Pose Examples</span>
      </div>
      <div className="flex flex-col gap-2 bg-black rounded-b p-3">
        <span className="text-bodySm text-white">Upload any image with a model to extract their pose.</span>

        <div className="flex items-center gap-2">
          {exampleImages.map((item, index) => (
            <img
              key={"ImageReferenceExamples_" + index}
              src={item}
              className="cursor-pointer w-[50px] h-[50px] rounded-sm"
              onClick={() => {
                setSelectedImage(index);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const DesignLeftMenu = ({ fineTuneValues, setFineTuneValues, preSelectedTrainingData }: any) => {
  const {
    isDeleting,
    setIsDeleting,
    images,
    selectedImageIndex,
    setSelectedImageIndex,
    isLoading,
    setAlreadyStartedCreating,
    setIsLoading,
    selectedTool,
    selectedDesignType,
    setSelectedDesignType,
    setDesignId,
    setImages,
    resetLeftMenu,
    setResetLeftMenu,
    setImagesInProcess,
    setIsOnLoadingItem,
    selectedDimension,
    setSelectedDimension,
    setShowGetFeedback,
  } = useDesignContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const promptAssistantRef = useRef<HTMLDivElement>(null);
  const [trainings, setTrainings] = useState<any>([]);
  const [selectedTrainings, setSelectedTrainings] = useState<any>(preSelectedTrainingData.length > 0 ? preSelectedTrainingData.map((item: any) => ({ name: item.name, train_id: item.id })) : []);
  const [referenceImageType, setReferenceImageType] = useState(ReferenceImageType.Sketch);
  const [plusAiActive, setPlusAiActive] = useState(false);
  const recursiveTimoutRef = useRef<any>(null);
  const accordionContainerRef = useRef<any>(null);
  const [focusTypes, setFocusTypes] = useState<any>([]);
  const [showChatBox, setShowChatBox] = useState(false);
  const [accordionIsActiveArr, setAccordionIsActiveArr] = useState([true, true, false, false, false, false, false, true]);
  const { isSketchAdded, user, tutorial } = useAppSelector((state) => state.common);
  const { handleUpload } = useS3();
  const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
  // const [isQuick, setIsQuick] = useState(false);
  const [isTechnical, setIsTechnical] = useState(false);

  // Add this near your other state declarations
  const [dynamicMaxLength, setDynamicMaxLength] = useState(800);

  // Add this function before the return statement
  const handleTextChange = (value: string) => {
    if (value.startsWith("[refabric_debug:]") && value.endsWith("[:refabric_debug]")) {
      setDynamicMaxLength(524288);
    } else {
      setDynamicMaxLength(800);
    }
  };

  useClickOutside(promptAssistantRef, () => setShowChatBox(false));

  function resetMenu() {
    setSelectedDimension(Dimensions.portrait);
    setReferenceImageType(ReferenceImageType.Sketch);
    setPlusAiActive(false);
    setAccordionIsActiveArr([true, true, false, false, false, false, false, true]);
    const defaultTraining = trainings.find((item: any) => item.id === "default");
    if (defaultTraining) {
      const formattedRecentTrends = { train_id: defaultTraining.id, name: defaultTraining.name, keyword: defaultTraining.keyword };
      setSelectedTrainings([formattedRecentTrends]);
    } else setSelectedTrainings([]);
    reset(); //back to default form values
  }

  //For tutorial Add This Sketch button
  async function uploadSketch() {
    const file = await fetch(sketchImage)
      .then((response) => response.blob())
      .then((blob) => new File([blob], "example-sketch-image.png"));

    const files = new Array(1);
    files[0] = file;
    const urlArray = await handleUpload({ files });

    setValue("imageSketch", urlArray[0].Location);
  }
  useEffect(() => {
    if (isSketchAdded) {
      handleAccordionClick(6);
      uploadSketch();
    }
  }, [isSketchAdded]);
  ///////////////////////////

  useEffect(() => {
    if (resetLeftMenu) {
      setImages([]);
      setDesignId("");
      resetMenu();
      navigate("/design");
      setIsLoading(false);
      setAlreadyStartedCreating(false);
      setResetLeftMenu(false);
      setCreateButtonDisabled(false);
      setIsTechnical(false);
    }
  }, [resetLeftMenu]);

  function stopRecursiveCall() {
    if (!recursiveTimoutRef.current || isLoading) return;
    clearTimeout(recursiveTimoutRef.current);
  }
  useEffect(() => {
    return () => {
      stopRecursiveCall();
    };
  }, []);

  function deactivateAccordion(index: number) {
    const temp = [...accordionIsActiveArr];
    temp[index] = false;
    setAccordionIsActiveArr(temp);
  }

  const handleAccordionClick = (index: number) => {
    const notChangeableIndexes = [0, 1, 7];

    if (notChangeableIndexes.includes(index)) return;

    if (!accordionIsActiveArr[index]) {
      const newAccordionIsActiveArr = [...accordionIsActiveArr]; // Create a new array

      newAccordionIsActiveArr[index] = !newAccordionIsActiveArr[index]; // Update the value at the specified index

      setAccordionIsActiveArr(newAccordionIsActiveArr); // Update the state with the new array
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fashion_prompt: "",
      model_prompt: "",
      background_prompt: "",
      negativePrompt: "",
      imageReference: "",
      imageSketch: "",
      imagePose: "",
      imageFabric: "",
      imageGuidance: "",
      sketchGuidance: "",
      focusType: "full_body_shot",
      trainingEffectLevel: TrainingEffectLevel.High,
      imageOutput: 4,
      generationMode: "enhanced",
    },
  });

  React.useEffect(() => {
    if (images.length === 0) {
      if (isDeleting) {
        resetMenu();
        setIsDeleting(false);
      }

      return;
    }
    if (!isLoading) {
      const tempArr = [...accordionIsActiveArr];
      setSelectedTrainings(images[selectedImageIndex]?.training_ids ?? []);
      setValue("fashion_prompt", images[selectedImageIndex]?.fashion_prompt ?? "");
      setValue("imageGuidance", images[selectedImageIndex]?.image_guidance ?? "");

      setValue("model_prompt", images[selectedImageIndex]?.model_prompt ?? "");
      if (images[selectedImageIndex]?.model_prompt) tempArr[2] = true;
      else tempArr[2] = false;
      setValue("background_prompt", images[selectedImageIndex]?.background_prompt ?? "");
      if (images[selectedImageIndex]?.background_prompt) tempArr[3] = true;
      else tempArr[3] = false;
      setValue("imageReference", images[selectedImageIndex]?.set_images?.reference_image ?? "");
      setValue("imageGuidance", images[selectedImageIndex]?.image_guidance ?? "");
      if (images[selectedImageIndex]?.set_images?.reference_image) tempArr[4] = true;
      else tempArr[4] = false;

      const referenceImageUrl = images[selectedImageIndex]?.set_images?.reference_image ?? "";
      if (referenceImageUrl) {
        fetch(referenceImageUrl, { method: "HEAD" })
          .then((res) => {
            if (res.ok) {
              setCreateButtonDisabled(false);
            } else {
              setCreateButtonDisabled(true);
            }
          })
          .catch(() => {
            setCreateButtonDisabled(true);
          });
      }

      setValue("imageFabric", images[selectedImageIndex]?.set_images?.fabric_image ?? "");
      if (images[selectedImageIndex]?.set_images?.fabric_image) tempArr[5] = true;
      else tempArr[5] = false;
      setValue("imagePose", images[selectedImageIndex]?.set_images?.position_image ?? "");
      if (images[selectedImageIndex]?.set_images?.position_image) tempArr[6] = true;
      else tempArr[6] = false;
      setValue("imageSketch", images[selectedImageIndex]?.set_images?.sketch_image ?? "");
      setIsTechnical(images[selectedImageIndex]?.is_technical === "True");
      if (images[selectedImageIndex]?.set_images?.sketch_image) tempArr[6] = true;
      else tempArr[6] = false;
      setAccordionIsActiveArr(tempArr);

      // setValue("negativePrompt", images[selectedImageIndex]?.negative_prompt ?? "");
      // setImageFieldReset(true);
    }
  }, [images]);

  const fashionPrompt = watch("fashion_prompt");
  const imageReference = watch("imageReference");
  const imageSketch = watch("imageSketch");
  const imagePose = watch("imagePose");
  const focusType = watch("focusType");
  const imageFabric = watch("imageFabric");
  const guidance = watch("imageGuidance");
  const trainingEffectLevel = watch("trainingEffectLevel");
  const imageOutput = watch("imageOutput");
  const generationMode = watch("generationMode");

  const recursiveAPICall = async (design_id: string) => {
    const prevImages = images;

    try {
      const res = await designService.getDesignStatus(design_id);

      if (res.responseData.images.length > 0) setImagesInProcess(res.responseData.images);

      if (res.responseData.status === 1) {
        if (window.location.pathname === "/design") {
          setIsOnLoadingItem(false);
          setImagesInProcess([]);
          navigate(`/design/${res.responseData.design_id}`);
          setIsLoading(false);
          setSelectedImageIndex(0);
          playSound();
          setShowGetFeedback(true);

          return;
        } else {
          setIsLoading(false);
          playSound();

          return;
        }
      }

      recursiveTimoutRef.current = setTimeout(() => recursiveAPICall(design_id), 3000);
    } catch (error: any) {
      setIsOnLoadingItem(false);
      setIsLoading(false);
      setAlreadyStartedCreating(false);
      setImages(prevImages);
      if (error?.response?.status !== 503) dispatch(setSnackbar({ message: "An error occurred while fetching design.", type: "error" }));
    }
  };

  const onValid = async (data: any) => {
    const filterData = (datax: Record<string, any>) => {
      return Object.fromEntries(Object.entries(datax).filter(([_, value]) => value !== null && value !== undefined && value !== ""));
    };

    const tempAnalyticsData = {
      training: selectedTrainings[0].name,
      training_effect: trainingEffectLevel,
      fashion_prompt: data.fashion_prompt,
      model: data.model_prompt,
      background: data.background_prompt,
      reference_image: data.imageReference,
      emphasis: data.imageGuidance,
      reference_fabric: data.imageFabric,
      sketch_pose: data.imageSketch ?? data.imagePose,
      focus: data.focusType,
      dimensions: selectedDimension,
    };

    const analyticsData = filterData(tempAnalyticsData);

    AnalyticsEvent("Created_Design", "", "click", analyticsData);

    setShowGetFeedback(false);
    const prevImages = images;
    setImages([]);
    setIsLoading(true);
    setAlreadyStartedCreating(true);

    if (tutorial.show) dispatch(setIsDesignTutorialCompleted(true));
    useLocalStorage().setItem(TUTORIAL_DESIGN_COMPLETED, true);

    const _trainIds = [{ train_id: "prompt", name: "Prompt", keyword: "prompt" }, ...selectedTrainings].map((item: any) => ({
      keyword: item.keyword,
      train_id: item.train_id,
      train_rate: item.train_id === "prompt" ? 50 : 50 / selectedTrainings.length,
    }));

    const isFineTuned = !!(fineTuneValues?.fashionPrompts || fineTuneValues?.modelPrompts || fineTuneValues?.backgroundPrompts);

    designService
      .startDesign({
        created_image: data.imageOutput,
        generation_mode: data.generationMode,
        title: "",
        train_ids: _trainIds,
        finetune_use: plusAiActive ? 1 : 0,
        model_id: "SG161222/Realistic_Vision_V5.1_noVAE",
        fashion_prompt: !accordionIsActiveArr[1] ? "" : fineTuneValues?.fashionPrompts ? fineTuneValues?.fashionPrompts : data.fashion_prompt,
        model_prompt: !accordionIsActiveArr[2] ? "" : fineTuneValues?.modelPrompts ? fineTuneValues?.modelPrompts : data.model_prompt,
        background_prompt: !accordionIsActiveArr[3] ? "" : fineTuneValues?.backgroundPrompts ? fineTuneValues?.backgroundPrompts : data.background_prompt,
        negative_prompt: data.negativePrompt ?? "",
        focus_prompt: isFineTuned ? "" : data.focusType ?? "",
        seed: isFineTuned ? images[0].seed : "-1",
        cfg_scale: 6.5,
        dimension: selectedDimension,
        steps: 25,
        image_guidance: !accordionIsActiveArr[4] ? "" : data.imageGuidance,
        sketch_guidance: !accordionIsActiveArr[6] ? "" : data.sketchGuidance, //this for both sketch & pose
        set_images: [
          {
            reference_image: !accordionIsActiveArr[4] ? "" : isFineTuned ? "" : data.imageReference ?? "",
            position_image: !accordionIsActiveArr[6] ? "" : isFineTuned ? "" : data.imagePose ?? "",
            sketch_image: !accordionIsActiveArr[6] ? "" : isFineTuned ? images[selectedImageIndex].path : data.imageSketch ?? "",
            fabric_image: !accordionIsActiveArr[5] ? "" : isFineTuned ? "" : data.imageFabric ?? "",
          },
        ],
        design_type: selectedDesignType !== DesignType.design && selectedDesignType !== DesignType.pattern ? DesignType.design : selectedDesignType ?? DesignType.design,
        // is_quick: isQuick ? "True" : "False",
        is_technical: isTechnical ? "True" : "False",
      })
      .then((res) => {
        setFineTuneValues(undefined);
        const design_id = res.responseData.design_id;
        recursiveAPICall(design_id);
      })
      .catch((error) => {
        setIsOnLoadingItem(false);
        setIsLoading(false);
        setAlreadyStartedCreating(false);
        setImages(prevImages);
        if (error?.response?.status !== 409 && error?.response?.status !== 402) dispatch(setSnackbar({ message: "An error occurred while creating design. Please try again.", type: "error" }));
      });
  };

  useEffect(() => {
    if (fineTuneValues !== undefined) onHandleSubmit();
  }, [fineTuneValues]);

  const onHandleSubmit = () => {
    handleSubmit(
      (data) => onValid(data),
      (e) => console.log(e)
    )();
  };

  const leftMenu = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!leftMenu.current) {
      return;
    }
    // Uncomment if we want to change sidebar to hidden by default.
    // leftMenu.current.classList.add("overflow-hidden", "grow-0", "basis-0", "h-0")
    // leftMenu.current.classList.remove("ml-6", "min-w-min")

    leftMenu.current.addEventListener("transitionend", (event) => {
      if (!leftMenu.current || event.target !== leftMenu.current) {
        return;
      }
      if (leftMenu.current.classList.contains("-translate-x-full")) {
        leftMenu.current.classList.add("overflow-hidden", "grow-0", "basis-0", "h-0");
        leftMenu.current.classList.remove("ml-6");
      }
    });

    leftMenu.current.addEventListener("transitionstart", (event) => {
      if (!leftMenu.current || event.target !== leftMenu.current) {
        return;
      }
      if (leftMenu.current.classList.contains("translate-x-0")) {
        leftMenu.current.classList.remove("overflow-hidden", "grow-0", "basis-0", "h-0");
      }
    });
  }, [selectedTool]);

  useEffect(() => {
    trainService.getTrains({ limit: 5000, page: 1 }).then((res) => {
      setTrainings(res.data);
    });
    staticsService.getFocusTypes().then((res) => setFocusTypes(res.responseData));

    return () => {
      stopRecursiveCall();
    };
  }, []);

  // Default Training yerlestirme
  useEffect(() => {
    if (trainings.length === 0) return;
    if (preSelectedTrainingData.length === 0 && (images.length === 0 || images[0].training_ids.length === 0)) {
      const defaultTraining = trainings.find((item: any) => item.id === "default");

      const formattedDefaultTraining = { train_id: defaultTraining.id, name: defaultTraining.name, keyword: defaultTraining.keyword };
      setSelectedTrainings((prev: any) => [...prev, formattedDefaultTraining]);
    }
  }, [trainings]);

  // const Randomize = () => (
  //   <Tooltip content="Randomize" position={"left"} zIndex={9000}>
  //     <div className="flex-center w-[22px] h-[22px] rounded-full bg-white">
  //       <IconDice className="w-4 h-4" />
  //     </div>
  //   </Tooltip>
  // );

  // const AddAttribute = () => (
  //   <Tooltip content="Add Attribute" contentClassName="!-translate-x-full">
  //     <div className="flex-center w-[22px] h-[22px] rounded-full bg-white">
  //       <IconAttribute className="w-4 h-4 text-black" />
  //     </div>
  //   </Tooltip>
  // );

  function handlePlusAIClick() {
    if (!plusAiActive) AnalyticsEvent("design_screen_plusAI_activated", "", "click");
    setPlusAiActive((prev) => !prev);
  }

  const FocusSelection = () => (
    <div
      className="flex flex-col gap-2"
      style={{
        pointerEvents: isLoading ? "none" : "auto",
      }}
    >
      <span className="text-h7 text-white">Focus</span>
      <div className="flex gap-[5px]">
        {focusTypes.map((item: any, index: number) => {
          const Icon = index === 0 ? IconFullBody : index === 1 ? IconPortrait1 : IconCloseUp;

          return (
            <div
              className={clsx(
                "flex-center gap-[5px] w-full rounded text-h7 py-1.5 cursor-pointer",
                focusType === item.key ? "bg-green text-trueBlack" : "hover:text-blackGreen bg-black-300 text-grey"
              )}
              key={item.key}
              onClick={() => {
                AnalyticsEvent(`design_screen_select_focus_${item?.key}`, "", "click");

                if (item.key === focusType) setValue("focusType", "");
                else setValue("focusType", item.key);
              }}
            >
              <Icon className="w-[18px] h-[18px] flex-shrink-0" /> {item.name}
            </div>
          );
        })}
      </div>
    </div>
  );

  const ImageOutput = () => (
    <div
      className="flex flex-col gap-2"
      style={{
        pointerEvents: isLoading ? "none" : "auto",
      }}
    >
      <span className="text-h7 text-white">Image Output</span>
      <div className="flex gap-[5px]">
        {ImageOutputCounts.map((item: any, index: number) => {
          return (
            <div
              className={clsx("flex-center gap-[5px] w-full rounded text-h7 py-1.5 cursor-pointer ", imageOutput === item ? "bg-green text-black-200" : "hover:text-green bg-black-300 text-grey")}
              key={item + "_" + index}
              onClick={() => {
                AnalyticsEvent(`design_screen_select_image_output_${item}`, "", "click");

                setValue("imageOutput", item);
              }}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );

  const GenerationMode = () => (
    <div
      className="flex flex-col gap-2"
      style={{
        pointerEvents: isLoading ? "none" : "auto",
      }}
    >
      <span className="text-h7 text-white">Generation Mode</span>
      <div className="flex gap-[5px]">
        {GenerationModes.map((item: any, index: number) => {
          const Icon = item.icon;

          return (
            <div
              className={clsx(
                "flex-center gap-[5px] w-full rounded text-h7 py-1.5 cursor-pointer ",
                generationMode === item.value ? "bg-green text-black-200" : "hover:text-green bg-black-300 text-grey"
              )}
              key={item + "_" + index}
              onClick={() => {
                AnalyticsEvent(`design_screen_select_generation_mode_${item.text}`, "", "click");

                setValue("generationMode", item.value);
              }}
            >
              <Icon className="w-[18px] h-[18px] flex-shrink-0" />
              {item.text}
            </div>
          );
        })}
      </div>
    </div>
  );

  const TrainingSelectFooter = ({ onClose }: any) => {
    function onSave() {
      handleAccordionClick(0);
      onClose();
    }

    function onClear() {
      if (user.packageCode.includes("design_studio")) setSelectedTrainings([]);
      onClose();
    }

    return (
      <div className="flex gap-2.5 w-full">
        <Button className="btn-secondary-small w-full" disabled={!user.packageCode.includes("design_studio")} onClick={onClear}>
          Clear
        </Button>
        <Button className="btn-primary-small w-full" onClick={onSave}>
          Save
        </Button>
      </div>
    );
  };

  return (
    <div
      ref={leftMenu}
      className={clsx(
        "flex relative flex-col h-full w-full max-w-[335px] bg-black-100 transition-transform duration-500 border-r border-black-300",
        selectedTool !== false ? "-translate-x-full overflow-hidden h-0 grow-0 basis-0" : "transalte-x-0"
      )}
      style={{ maxHeight: "calc(100vh - 56px)" }}
    >
      <div className="absolute left-full ml-4 py-4 z-50 h-full" ref={promptAssistantRef}>
        <PromptAssistant showChatBox={showChatBox} setShowChatBox={setShowChatBox} handleCallback={(text: string) => setValue("fashion_prompt", text)} />
      </div>
      <div ref={accordionContainerRef} className="flex overflow-y-scroll no-scrollbar h-full flex-col w-full">
        <div className="flex w-full p-3 border-b border-black-300">
          <Tab
            initTab={selectedDesignType !== DesignType.design && selectedDesignType !== DesignType.pattern ? DesignType.design : selectedDesignType ?? DesignType.design}
            onChange={(value: any) => {
              // window.history.replaceState(null, "", "/design");
              // setImages([]);
              // setAlreadyStartedCreating(false);
              // resetMenu();
              setSelectedDesignType(value);
            }}
          >
            <Tab.Item id={DesignType.design} className="!w-full">
              <div className="flex-center gap-[5px] w-full">
                <IconStyle className="text-trueBlack dark:text-green w-[18px] h-[18px]" />
                Style
              </div>
            </Tab.Item>
            <Tab.Item id={DesignType.pattern} disabled={true}>
              <div className="flex-center gap-[5px] w-full">
                <IconPattern className="w-[18px] h-[18px]" />
                Pattern
              </div>
            </Tab.Item>
          </Tab>
        </div>

        <Accordion
          disabled={isLoading}
          isOptional={false}
          defaultIsOpen={true}
          isActive={accordionIsActiveArr[0]}
          menuRef={accordionContainerRef}
          onToggle={() => handleAccordionClick(0)}
          title="Select a training*"
          icon={IconTrain}
          iconClassName="text-green"
        >
          <div className="flex-1 flex flex-col w-full gap-3 px-3 pb-3">
            <TrainingSelect Footer={TrainingSelectFooter} trainings={trainings} selectedTrainings={selectedTrainings} setSelectedTrainings={setSelectedTrainings} />
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1">
                <span className="text-h7 text-white">Training Effect</span>
                <Tooltip content={"Choose the effect level to control how strongly the training shapes your design"} contentClassName="!max-w-[225px]" zIndex={10} position={"top"}>
                  <IconHelp className="text-purple dark:text-green w-4 h-4" />
                </Tooltip>
              </div>
              <Tab
                initTab={trainingEffectLevel ?? TrainingEffectLevel.High}
                onChange={(value: any) => {
                  setValue("trainingEffectLevel", value);
                }}
              >
                <Tab.Item id={TrainingEffectLevel.Low} className="!w-full">
                  <div className="flex-center gap-[5px] w-full">
                    <IconLevel1 className="w-[18px] h-[18px]" />
                    Low
                  </div>
                </Tab.Item>
                <Tab.Item id={TrainingEffectLevel.Medium}>
                  <div className="flex-center gap-[5px] w-full">
                    <IconLevel2 className="w-[18px] h-[18px]" />
                    Medium
                  </div>
                </Tab.Item>
                <Tab.Item id={TrainingEffectLevel.High}>
                  <div className="flex-center gap-[5px] w-full">
                    <IconLevel3 className="w-[18px] h-[18px]" />
                    High
                  </div>
                </Tab.Item>
              </Tab>
            </div>
          </div>
        </Accordion>

        <Accordion
          disabled={isLoading}
          isOptional={false}
          defaultIsOpen={true}
          isActive={accordionIsActiveArr[1]}
          menuRef={accordionContainerRef}
          onToggle={() => handleAccordionClick(1)}
          title="Define Fashion Item"
          icon={IconDraw}
        >
          <div className="flex-1 flex flex-col w-full gap-3 px-3 pb-3">
            {selectedDesignType === DesignType.design ? (
              <AnimatedGradientBorder isActive={plusAiActive} inActiveBorder="!border !border-black-300">
                <div className="flex flex-col w-full rounded-[5px]">
                  <Textarea
                    maxLength={dynamicMaxLength}
                    containerClassName="!border-none !rounded-b-none"
                    className="h-[50px] max-h-[150px]"
                    placeholder="A fashionable woman wearing a fitted off-shoulder top paired with high-waisted wide-leg trousers and block heels, accessorized with a leather belt and a sun hat, walking through a bustling city street"
                    {...register("fashion_prompt", {
                      onChange: (e) => handleTextChange(e.target.value),
                    })}
                    error={errors.fashion_prompt?.message}
                    PlusAIcomponent={<PlusAI isActive={plusAiActive} handleClick={handlePlusAIClick} />}
                  />
                  <button
                    className={clsx(
                      "cursor-pointer flex items-center gap-[5px] bg-black hover:bg-black-300 p-[5px] rounded-b text-bodySm text-white border-t border-black-300",
                      showChatBox ? "pointer-events-none" : ""
                    )}
                    onClick={() => {
                      if (!showChatBox) AnalyticsEvent("design_screen_prompt_assistant_opened", "", "click");
                      setShowChatBox((prev) => !prev);
                    }}
                  >
                    <IconAssitant className="w-[14px] h-[14px]" />
                    <span className="w-full">Hello 👋 Do you need help on prompting?</span>
                    <IconRightArrow className="ml-auto w-[14px] h-[14px]" />
                  </button>
                </div>
              </AnimatedGradientBorder>
            ) : (
              <>
                <div className="flex flex-col gap-2.5">
                  <div className="flex justify-between">
                    <span className="text-h7 text-white">Pattern Prompt</span>
                  </div>
                  <Textarea
                    maxLength={800}
                    className="max-h-[150px]"
                    placeholder="Enter detailed description of the pattern you want to create"
                    {...register("fashion_prompt")}
                    error={errors.fashion_prompt?.message}
                  />
                </div>
                <div className="flex flex-col gap-2.5">
                  <div className="flex justify-between">
                    <span className="text-h7 text-white">What do you design for?</span>
                  </div>
                  <Textarea className="max-h-[150px]" placeholder="e.g. shirt, dress, shoes" />
                </div>
              </>
            )}
            {/* {selectedDesignType === DesignType.design && focusTypes.length > 0 && <FocusSelection />}
            <span className="text-h7 text-white">Dimensions</span>
            {selectedDesignType === DesignType.design && <DimensionSelection />} */}
          </div>
        </Accordion>

        <Accordion
          disabled={isLoading}
          isOptional={true}
          isActive={accordionIsActiveArr[2]}
          deactivateAccordion={() => deactivateAccordion(2)}
          menuRef={accordionContainerRef}
          onToggle={() => handleAccordionClick(2)}
          title="Model Prompt"
          icon={IconModel}
          deactivateCallback={() => setValue("model_prompt", "")}
        >
          <div className="flex-1 px-3 pb-3">
            <AnimatedGradientBorder isActive={plusAiActive}>
              <Textarea
                maxLength={800}
                PlusAIcomponent={<PlusAI isActive={plusAiActive} handleClick={handlePlusAIClick} />}
                containerClassName="!py-[9px]"
                className="max-h-[150px]"
                placeholder="Describe the model in details"
                {...register("model_prompt")}
                error={errors.model_prompt?.message}
              />
            </AnimatedGradientBorder>
          </div>
        </Accordion>

        <Accordion
          disabled={isLoading}
          isOptional={true}
          isActive={accordionIsActiveArr[3]}
          deactivateAccordion={() => deactivateAccordion(3)}
          menuRef={accordionContainerRef}
          onToggle={() => handleAccordionClick(3)}
          title="Background Prompt"
          icon={IconRemoveBg}
          deactivateCallback={() => setValue("background_prompt", "")}
        >
          <div className="flex-1 px-3 pb-3">
            <AnimatedGradientBorder isActive={plusAiActive}>
              <Textarea
                maxLength={800}
                PlusAIcomponent={<PlusAI isActive={plusAiActive} handleClick={handlePlusAIClick} />}
                containerClassName="!py-[9px]"
                className="max-h-[150px]"
                placeholder="Describe the background in details"
                {...register("background_prompt")}
                error={errors.background_prompt?.message}
              />
            </AnimatedGradientBorder>
          </div>
        </Accordion>

        <Accordion
          disabled={isLoading}
          isOptional={true}
          isActive={accordionIsActiveArr[4]}
          deactivateAccordion={() => deactivateAccordion(4)}
          menuRef={accordionContainerRef}
          onToggle={() => handleAccordionClick(4)}
          title="Reference Image"
          icon={IconCamera}
          iconClassName="text-orange"
          deactivateCallback={() => {
            setValue("imageReference", "");
            setValue("imageGuidance", "");
          }}
        >
          <div className="flex-1 px-3 pb-3">
            <div className="flex flex-col gap-2.5">
              <UploadBox
                location="design_screen_reference_image_upload"
                className="!h-[100px]"
                value={imageReference}
                text="Drag & Drop to upload file or click here"
                icon={IconUpload}
                minWidth={MIN_WIDTH_HEIGHT_REFERENCE_IMAGE}
                minHeight={MIN_WIDTH_HEIGHT_REFERENCE_IMAGE}
                callback={(url: string) => {
                  setCreateButtonDisabled(false);
                  AnalyticsEvent("design_screen_referance_image_uploaded", "", "click");
                  setValue("imageReference", url);
                  setValue("imageGuidance", GuidanceScaleType.Variation);
                }}
                removeCallback={() => {
                  setCreateButtonDisabled(false);
                  setValue("imageGuidance", "");
                }}
                imageErrorCallback={() => {
                  setCreateButtonDisabled(true);
                }}
              />
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <span className="text-white text-h7">Emphasis</span>
                  <Tooltip zIndex={49} content="Choose variation to emphasize the reference image or inspiration to emphasize the prompt." contentClassName="!w-[220px]" position="right">
                    <IconHelp className="flex-shrink-0 w-4 h-4 text-purple dark:text-green" />
                  </Tooltip>
                </div>
                <Tab
                  initTab={guidance ? guidance : ""}
                  onChange={(value: any) => {
                    setValue("imageGuidance", value);
                  }}
                >
                  <Tab.Item id={GuidanceScaleType.Inspiration}>
                    <div className="flex-center gap-[5px] w-full">
                      <IconInspiration className="w-[18px] h-[18px]" />
                      Inspiration
                    </div>
                  </Tab.Item>

                  <Tab.Item id={GuidanceScaleType.Variation}>
                    <div className="flex-center gap-[5px] w-full">
                      <IconVariation className="w-[18px] h-[18px]" />
                      Variation
                    </div>
                  </Tab.Item>
                </Tab>
              </div>
              {guidance === "weak" && <ReferenceInspirationExamples />}
              {guidance === "strong" && <ReferenceVariationExamples />}
            </div>
          </div>
        </Accordion>

        <Accordion
          disabled={isLoading}
          isOptional={true}
          isActive={accordionIsActiveArr[5]}
          deactivateAccordion={() => deactivateAccordion(5)}
          menuRef={accordionContainerRef}
          onToggle={() => handleAccordionClick(5)}
          title="Reference Fabric"
          icon={IconFabric}
          iconClassName="text-orange"
          deactivateCallback={() => setValue("imageFabric", "")}
        >
          <div className="flex-1 px-3 pb-3">
            <UploadBox
              location="design_screen_reference_fabric_upload"
              className="!h-[100px]"
              value={imageFabric}
              text="Drag & Drop to upload file or click here"
              icon={IconUpload}
              minHeight={MIN_WIDTH_HEIGHT_REFERENCE_FABRIC}
              minWidth={MIN_WIDTH_HEIGHT_REFERENCE_FABRIC}
              callback={(url: string) => {
                setCreateButtonDisabled(false);
                AnalyticsEvent("design_screen_reference_fabric_uploaded", "", "click");
                setValue("imageFabric", url);
              }}
              removeCallback={() => {
                setCreateButtonDisabled(false);
              }}
              imageErrorCallback={() => {
                setCreateButtonDisabled(true);
              }}
            />
          </div>
        </Accordion>

        <Accordion
          disabled={isLoading}
          isOptional={true}
          isActive={accordionIsActiveArr[6]}
          deactivateAccordion={() => deactivateAccordion(6)}
          menuRef={accordionContainerRef}
          onToggle={() => handleAccordionClick(6)}
          title="Sketch & Pose"
          icon={IconSketch}
          iconClassName="text-orange"
          deactivateCallback={() => {
            setValue("imagePose", "");
            setValue("imageSketch", "");
            setIsTechnical(false);
          }}
        >
          <div className="flex-1 px-3 pb-3">
            {selectedDesignType !== DesignType.pattern && (
              <div className="flex flex-col gap-2">
                <Tab
                  initTab={
                    imageSketch !== ""
                      ? ReferenceImageType.Sketch
                      : imagePose !== ""
                      ? ReferenceImageType.Pose
                      : images[selectedImageIndex]?.set_images?.sketch_image !== ""
                      ? ReferenceImageType.Sketch
                      : images[selectedImageIndex]?.set_images?.position_image !== ""
                      ? ReferenceImageType.Pose
                      : ReferenceImageType.Sketch
                  }
                  onChange={(value: any) => {
                    setReferenceImageType(value);
                  }}
                >
                  <Tab.Item id={ReferenceImageType.Sketch}>
                    <div className="flex-center gap-[5px] w-full">
                      <IconSketch className="w-[18px] h-[18px]" />
                      Sketch
                    </div>
                  </Tab.Item>
                  <Tab.Item id={ReferenceImageType.Pose}>
                    <div className="flex-center gap-[5px] w-full">
                      <IconPose className="w-[18px] h-[18px]" />
                      Pose
                    </div>
                  </Tab.Item>
                </Tab>
                {referenceImageType === ReferenceImageType.Sketch && (
                  <UploadBox
                    location="design_screen_sketch_upload"
                    className="!h-[100px]"
                    value={imageSketch}
                    text="Drag & Drop to upload file or click here"
                    icon={IconUpload}
                    callback={(url: string) => {
                      setCreateButtonDisabled(false);
                      AnalyticsEvent("design_screen_sketch_uploaded", "", "click");
                      setValue("imagePose", "");
                      setValue("imageSketch", url);
                    }}
                    removeCallback={() => {
                      setCreateButtonDisabled(false);
                    }}
                    imageErrorCallback={() => {
                      setCreateButtonDisabled(true);
                    }}
                    minHeight={MIN_WIDTH_HEIGHT_REFERENCE_SKETCH}
                    minWidth={MIN_WIDTH_HEIGHT_REFERENCE_SKETCH}
                  />
                )}
                {referenceImageType === ReferenceImageType.Pose && (
                  <UploadBox
                    location="design_screen_pose_upload"
                    className="!h-[100px]"
                    text="Drag & Drop to upload file or click here"
                    value={imagePose}
                    icon={IconUpload}
                    callback={(url: string) => {
                      setCreateButtonDisabled(false);
                      AnalyticsEvent("design_screen_pose_image_uploaded", "", "click");
                      setValue("imageSketch", "");
                      setValue("imagePose", url);
                    }}
                    removeCallback={() => {
                      setCreateButtonDisabled(false);
                    }}
                    imageErrorCallback={() => {
                      setCreateButtonDisabled(true);
                    }}
                  />
                )}

                {referenceImageType === ReferenceImageType.Sketch && (
                  <CheckBox
                    checked={isTechnical}
                    containerClassName="flex items-center gap-2.5 cursor-pointer"
                    boxClassName="border-none bg-black-300"
                    checkedColor="bg-green"
                    onChange={(_: any, _isChecked: boolean) => {
                      setIsTechnical(_isChecked);
                    }}
                  >
                    <span className="text-h7 text-white">This is a technical drawing</span>
                  </CheckBox>
                )}

                {referenceImageType === ReferenceImageType.Sketch && <ReferenceSketchExamples />}
                {referenceImageType === ReferenceImageType.Pose && <ReferencePoseExamples />}
              </div>
            )}
          </div>
        </Accordion>

        <Accordion
          disabled={isLoading}
          isOptional={false}
          defaultIsOpen={true}
          isActive={accordionIsActiveArr[7]}
          menuRef={accordionContainerRef}
          onToggle={() => handleAccordionClick(7)}
          title="Settings"
          icon={IconSettings}
          iconClassName="text-orange"
        >
          <div className="flex-1 flex flex-col gap-3 px-3 pb-3">
            <FocusSelection />
            <DimensionSelection disabled={isLoading} setSelectedDimension={setSelectedDimension} selectedDesignType={selectedDesignType} selectedDimension={selectedDimension} />
            {/* <ImageOutput />
            <GenerationMode /> */}
          </div>
        </Accordion>

        {/* <Accordion menuRef={accordionContainerRef} open={openAccordion === 3} onToggle={() => handleAccordionClick(3)} title="Advanced settings" icon={IconSettings} iconClassName="text-pink">
          <span className="text-h7 text-white mb-2.5">Guidance Scale</span>
          <GuidanceScaleItems sliders={sliders} setSliders={setSliders} data={selectedTrainings} onRemove={onRemove} />
        </Accordion> */}
      </div>
      <div className="flex flex-col gap-2.5 self-end w-full p-2.5 border-t border-black-300 bg-black">
        {/* <div className="flex items-center justify-between">
          <div className="flex items-center gap-2.5 text-white">
            <IconSpeed className="w-[18px] h-[18px]" />

            <div className="flex items-center gap-1 text-white">
              <span className="text-h7">Are you in a hurry?</span>
              <Tooltip content={"Perfect for when you're short on time, though the standard way offers more refined results."} contentClassName="!w-[250px]" position={"top"}>
                <IconHelp className="w-4 h-4 text-green flex-shrink-0" />
              </Tooltip>
            </div>
          </div>
          <ToggleButton
            trackClassName="!bg-black-300 !h-3 !w-8 !border-none"
            thumbClassName="!h-5 !w-5 !bg-grey-light"
            checkedThumbClassName="!bg-green"
            onToggle={() => {
              setIsQuick((prev) => !prev);
            }}
          />
        </div> */}
        <Button
          className="btn-primary w-full"
          disabled={createButtonDisabled || isLoading || (fashionPrompt === "" && imageReference === "")}
          onClick={() => {
            AnalyticsEvent("create_button_clicked", "", "click");

            onHandleSubmit();
          }}
        >
          Create
        </Button>
      </div>
    </div>
  );
};

export default DesignLeftMenu;
