import React, { useState } from "react";
import Button from "components/Button";
import Modal from "components/Modal";
import { IconCollection, IconDesign, IconTrend, ImageTrainingQueueContainer } from "icons";
import { useNewTrainingContext } from "./NewTrainingContext";
import trainService from "api/train/train.service";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { AnalyticsEvent } from "utils";
import { useDispatch } from "react-redux";
import { setIsTrainTutorialCompleted } from "store/commonSlice";
import { useAppSelector } from "store";
import { TUTORIAL_TRAIN_COMPLETED, useLocalStorage } from "hooks/useLocalStorage";

export const TrainingInQueueModal = ({ queueCount, show, onClose, Elements }: any) => {
  return (
    <Modal onClose={onClose} show={show} bodyClassName="!rounded-[5px] !border-none no-scrollbar" backdropDisabled={true} className="!z-[9999]">
      <div className="flex p-5 bg-black rounded-[10px]">
        <TrainingInQueueContent Elements={Elements} queueCount={queueCount} />
      </div>
    </Modal>
  );
};

export const queueCountProvider = (queueCount: any) => {
  let count = queueCount;
  // if (count === 0) count = "1st";
  // if (count === 1) count = "2nd";
  if (count === 2) count = "1st";
  if (count === 3) count = "2nd";
  if (count === 4) count = "3rd";
  if (count > 4) {
    const _count = count - 1;
    const lastDigit = _count % 10;
    const lastTwoDigits = _count % 100;
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      count = _count + "th";
    } else if (lastDigit === 1) {
      count = _count + "st";
    } else if (lastDigit === 2) {
      count = _count + "nd";
    } else if (lastDigit === 3) {
      count = _count + "rd";
    } else {
      count = _count + "th";
    }
  }

  return count;
};

const TrainingInQueueContent = ({ queueCount, Elements }: any) => {
  return (
    <div className="flex flex-col gap-5 w-[520px]">
      <div className="flex items-center flex-col w-full gap-2.5">
        <div className="relative flex-center">
          <img src={ImageTrainingQueueContainer} />
          <h3 className="absolute text-h3 text-white">{queueCount + 1}</h3>
        </div>
        <h3 className="text-h3 text-white">Training in Queue!</h3>
        <span className="text-bodySm text-white text-center">
          {queueCount !== undefined && (
            <span>
              You are currently <b>{queueCountProvider(queueCount)} in line</b>.
            </span>
          )}{" "}
          Your training will be ready in approximately {queueCount === 0 ? 1 : queueCount ? Number(queueCount) + 1 : "1-2"} hour(s). You’ll be notified once it’s ready.
        </span>
      </div>
      <div className="flex flex-col gap-2">
        {Elements.map((item: any, index: number) => (
          <Element key={item.title} icon={item.icon} title={item.title} index={index} onClick={item.onClick} />
        ))}
      </div>
    </div>
  );
};

const Element = ({ icon, title, onClick, index }: any) => {
  const Icon = icon;

  return (
    <div className="cursor-pointer flex items-center p-5 gap-5 rounded-md border border-black-300 hover:bg-black-300" onClick={onClick}>
      <Icon className={clsx(index === 0 ? "text-green" : index === 1 ? "text-purple" : "text-orange")} />
      <h6 className="text-h6 text-white">{title}</h6>
    </div>
  );
};

const StartTrainingModal = ({ onClose, show }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { trainId } = useNewTrainingContext();
  const { isTrainTutorialCompleted, tutorial } = useAppSelector((state) => state.common);
  const [isTrainingStarted, setIsTrainingStarted] = useState(false);
  const [disableBtn, setdisableBtn] = useState(false);
  const [queueCount, setQueueCount] = useState<any>("");

  const Elements = [
    {
      icon: IconDesign,
      title: "Create New Design",
      onClick: () => {
        AnalyticsEvent("new_training_popup_create_new_design", "", "click");
        navigate("/design");
      },
    },
    {
      icon: IconCollection,
      title: "Browse Your Collections",
      onClick: () => {
        AnalyticsEvent("new_training_popup_browse_collection", "", "click");
        navigate("/collections");
      },
    },
    {
      icon: IconTrend,
      title: "Explore New Trends",
      onClick: () => {
        AnalyticsEvent("new_training_popup_explore_new_trends", "", "click");
        navigate("/");
      },
    },
  ];

  function handleClose() {
    if (isTrainTutorialCompleted) navigate("/trainings");
  }

  function onStartTraining() {
    if (tutorial.show) dispatch(setIsTrainTutorialCompleted(true));
    useLocalStorage().setItem(TUTORIAL_TRAIN_COMPLETED, true);

    setdisableBtn(true);
    trainService
      .startTrain(trainId)
      .then((res) => {
        setQueueCount(res.responseData[2].que_count);
        setIsTrainingStarted(true);
      })
      .catch((err) => console.log(err))
      .finally(() => setdisableBtn(false));
  }

  const Footer = () => (
    <>
      <Button className="btn-secondary w-full" onClick={onClose}>
        Cancel
      </Button>
      <Button className="btn-primary w-full" disabled={disableBtn} onClick={onStartTraining}>
        Start Training
      </Button>
    </>
  );

  const RenderContent = () =>
    !isTrainingStarted ? (
      <span className="text-bodySm text-white w-[280px]">Are you sure that you want to start training your images?</span>
    ) : (
      <TrainingInQueueContent queueCount={queueCount} Elements={Elements} />
    );

  return (
    <Modal onClose={handleClose} show={show} bodyClassName="!rounded-[5px] !border-none no-scrollbar" className="!z-[9999]">
      <div className="flex p-5 bg-black rounded-t-[10px]">{RenderContent()}</div>
      {!isTrainingStarted && (
        <div className="flex gap-2.5 p-2.5 bg-black-100 rounded-b-[10px]">
          <Footer />
        </div>
      )}
    </Modal>
  );
};

export default StartTrainingModal;
