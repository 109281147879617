import React, { useState } from "react";

const useDragUpload = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [counter, setCounter] = useState(0);
  // const [droppedFiles, setDroppedFiles] = useState<any>([]);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setCounter(counter + 1);
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setCounter(counter - 1);

    if (counter - 1 === 0) {
      setIsDragging(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, callback?: (files: any) => void) => {
    e.preventDefault();
    setIsDragging(false);
    setCounter(0);

    if (callback) callback(e.dataTransfer.files);
  };

  return { handleDragEnter, handleDragLeave, handleDrop, isDragging };
};

export default useDragUpload;
