/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import Button from "components/Button";
import { IconCheck, IconClose, IconCollection, IconDrag, IconRightArrow } from "icons";
import { useAppSelector } from "store";
import Modal from "components/Modal";
import { useDispatch } from "react-redux";
import { setIsDesignTutorialCompleted, setIsEditTutorialCompleted, setIsTrainTutorialCompleted, setTutorial } from "store/commonSlice";
import { TUTORIAL_DESIGN_COMPLETED, TUTORIAL_EDIT_COMPLETED, TUTORIAL_TRAIN_COMPLETED, useLocalStorage } from "hooks/useLocalStorage";
import { EditImageTutorialData } from "../TutorialData";
import clsx from "clsx";
import Lottie from "lottie-react";
import lottieData from "./lottie.json";

export enum TutorialType {
  Design,
  Train,
  EditImage,
}

const TutorialItem = () => {
  const dispatch = useDispatch();
  const { tutorial, isDesignTutorialCompleted, isEditTutorialCompleted, isTrainTutorialCompleted } = useAppSelector((state) => state.common);
  const { show, data, type } = tutorial;

  const [currentStep, setCurrentStep] = useState(type === TutorialType.EditImage && window.location.pathname.includes("design") ? 2 : 1);
  const [isVideoLoading, setIsVideoLoading] = useState(!!data[currentStep]?.image);
  const [showExitModal, setShowExitModal] = useState(false);
  const [isTutorialCompleted, setIsTutorialCompleted] = useState(false);

  useEffect(() => {
    if (useLocalStorage().getItem(TUTORIAL_DESIGN_COMPLETED) === null) {
      useLocalStorage().setItem(TUTORIAL_DESIGN_COMPLETED, false);
      useLocalStorage().setItem(TUTORIAL_TRAIN_COMPLETED, false);
      useLocalStorage().setItem(TUTORIAL_EDIT_COMPLETED, false);
    }
  }, []);

  useEffect(() => {
    const isCompleted = type === TutorialType.Design ? isDesignTutorialCompleted : type === TutorialType.Train ? isTrainTutorialCompleted : isEditTutorialCompleted;
    if (isCompleted && currentStep === data.length - 1) {
      if (type === TutorialType.Design) {
        useLocalStorage().setItem(TUTORIAL_DESIGN_COMPLETED, true);
        dispatch(setIsDesignTutorialCompleted(false));
      } else if (type === TutorialType.Train) {
        useLocalStorage().setItem(TUTORIAL_TRAIN_COMPLETED, true);
        dispatch(setIsTrainTutorialCompleted(false));
      } else {
        useLocalStorage().setItem(TUTORIAL_EDIT_COMPLETED, true);
        dispatch(setIsEditTutorialCompleted(false));
      }

      setIsTutorialCompleted(true);
    }
  }, [isDesignTutorialCompleted, isTrainTutorialCompleted, isEditTutorialCompleted]);

  useEffect(() => {
    setIsVideoLoading(!!data[currentStep]?.image);
  }, [currentStep]);

  useEffect(() => {
    if (!show) {
      setIsTutorialCompleted(false);
      setCurrentStep(1);
    }
  }, [show]);

  function onNext() {
    setIsVideoLoading(false);
    const nextIndex = currentStep + 1;
    if (nextIndex >= data.length) return;
    setCurrentStep(nextIndex);
  }

  function onBack() {
    setIsVideoLoading(false);
    const previousIndex = currentStep - 1;
    if (previousIndex <= 0) return;

    setCurrentStep(previousIndex);
  }

  function onComplete() {
    setIsTutorialCompleted(true);
    if (type === TutorialType.Design) {
      useLocalStorage().setItem(TUTORIAL_DESIGN_COMPLETED, true);
      dispatch(setIsDesignTutorialCompleted(false));
    } else if (type === TutorialType.Train) {
      useLocalStorage().setItem(TUTORIAL_TRAIN_COMPLETED, true);
      dispatch(setIsTrainTutorialCompleted(false));
    } else {
      useLocalStorage().setItem(TUTORIAL_EDIT_COMPLETED, true);
      dispatch(setIsEditTutorialCompleted(false));
    }
  }

  function handleVideoLoad() {
    setIsVideoLoading(false);
  }

  const ExitTutorialModal = () => (
    <Modal
      onClose={() => {
        setShowExitModal(false);
      }}
      show={showExitModal}
      className="!z-[10001]"
      bodyClassName="!border-none !rounded-none !w-[320px]"
    >
      <div className="flex flex-col">
        <div className="flex p-2.5 rounded-t-[5px] bg-black-300">
          <h6 className="text-white text-h6">Exit the tutorial</h6>
        </div>
        <div className="flex border-x border-black-300 p-[14px] bg-black">
          <span className="text-bodySm text-white">Are you sure you want to leave? Any progress you've made will not be saved.</span>
        </div>
        <div className="flex gap-2.5 p-2.5 rounded-b-[5px] bg-black-100 border border-black-300">
          <Button
            className="btn-secondary-small w-full"
            onClick={() => {
              setShowExitModal(false);
            }}
          >
            No, Continue
          </Button>
          <Button
            className="btn-primary-small w-full"
            onClick={() => {
              dispatch(setTutorial({ show: false, type: null, data: [] }));
              setShowExitModal(false);
            }}
          >
            Yes, Exit
          </Button>
        </div>
      </div>
    </Modal>
  );

  const TutorialCompleted = () => {
    const isAllTutorialsCompleted = useLocalStorage().getItem(TUTORIAL_EDIT_COMPLETED) && useLocalStorage().getItem(TUTORIAL_DESIGN_COMPLETED) && useLocalStorage().getItem(TUTORIAL_TRAIN_COMPLETED);

    return (
      <div className="relative flex items-center w-[510px] flex-col p-[15px] gap-4 bg-black rounded-xl border border-black-300">
        <div className="flex-center h-12 w-12 rounded-full bg-black-300">
          <IconCheck className="w-7 h-7 text-green" />
        </div>
        <div className="absolute bottom-[230px]">
          <Lottie animationData={lottieData} loop={false} className=" h-[225px]" />
        </div>
        <div className="flex flex-col px-5 text-center">
          <h3 className="text-h3 text-white">Voila! You’ve completed this tutorial!</h3>
          {isAllTutorialsCompleted ? (
            <span className="text-bodyMd text-white">Congratulations, all tutorials are completed! You've mastered the magic of AI with Refabric!</span>
          ) : (
            <span className="text-bodyMd text-white">Let’s continue with another one to keep explore Refabric’s magic.</span>
          )}
        </div>
        {isAllTutorialsCompleted ? (
          <></>
        ) : (
          type === TutorialType.Design &&
          !useLocalStorage().getItem(TUTORIAL_EDIT_COMPLETED) && (
            <div
              className="cursor-pointer hover:bg-black-100 flex items-center gap-5 p-4 border border-black-300 rounded-md"
              onClick={() => {
                setCurrentStep(2);
                setIsTutorialCompleted(false);
                dispatch(setTutorial({ show: true, type: TutorialType.EditImage, data: EditImageTutorialData }));
              }}
            >
              <IconCollection className="text-orange flex-shrink-0" />
              <div className="flex flex-col gap-2.5">
                <h6 className="text-h6 text-white">Edit your image</h6>
                <span className="text-bodySm text-white">Master the fashion design with AI! Try our editing tools.</span>
              </div>
              <IconRightArrow className="text-white flex-shrink-0" />
            </div>
          )
        )}
        <Button className="btn-primary-small" onClick={() => dispatch(setTutorial({ show: false, type: null, data: [] }))}>
          Close
        </Button>
      </div>
    );
  };

  return (
    <div
      style={{ zIndex: 9990 }}
      className={clsx("fixed bottom-[50px]", type === TutorialType.EditImage && currentStep >= 3 && window.location.pathname.includes("design/") ? "left-[50px]" : "right-[50px]")}
    >
      <ExitTutorialModal />
      {show ? (
        isTutorialCompleted ? (
          <TutorialCompleted />
        ) : (
          <div className="relative flex flex-col gap-4 pt-8 pb-10 bg-black rounded-xl border border-black-300 w-[470px] overflow-hidden" style={{ maxHeight: "calc(100vh - 140px)" }}>
            <div className="absolute top-0 pt-2 flex w-full justify-between items-center px-[15px]">
              <span className="text-headline text-white uppercase">
                step {currentStep}/{data.length - 1}
              </span>
              <IconClose className="cursor-pointer w-5 h-5 text-grey" onClick={() => setShowExitModal(true)} />
            </div>
            <div className="flex flex-col gap-4 overflow-y-scroll no-scrollbar pt-1 pb-10">
              <div className="flex flex-col gap-2.5 px-[15px]">
                <div
                  className={clsx(
                    "flex-center relative rounded-[3px] border-[3px] aspect-[1.66]",
                    type === TutorialType.Design ? "border-pink" : type === TutorialType.Train ? "border-green" : "border-orange"
                  )}
                >
                  {isVideoLoading && (
                    <div className="absolute h-[2px] w-3/4 bg-white bg-opacity-10 rounded-full overflow-hidden">
                      <div className="h-full bg-green w-1/2 animate-image-upload" />
                    </div>
                  )}
                  <video src={data[currentStep]?.image} autoPlay={true} loop={true} muted={true} preload="auto" onLoadedData={handleVideoLoad} />
                </div>
              </div>
              <div className="flex flex-col gap-2 px-[15px]">
                <h5 className="text-h5 text-white">{typeof data[currentStep].header === "function" ? data[currentStep].header() : data[currentStep].header}</h5>
                <span className="text-bodySm text-white">{typeof data[currentStep].description === "function" ? data[currentStep].description() : data[currentStep].description}</span>
              </div>
              {data[currentStep].content && <div className="flex px-[15px]">{data[currentStep].content()}</div>}
            </div>
            <div className="absolute bottom-0 pt-3 pb-2 flex flex-col w-full items-center px-[15px] bg-black">
              <div className="flex justify-between w-full items-center">
                {currentStep !== 1 && (
                  <Button className="flex-start btn-secondary-small" onClick={onBack}>
                    Back
                  </Button>
                )}
                {currentStep !== data.length - 1 ? (
                  <Button className="ml-auto btn-primary-small" onClick={onNext}>
                    Next
                  </Button>
                ) : (
                  <Button className="ml-auto btn-primary-small" onClick={onComplete}>
                    Complete
                  </Button>
                )}
              </div>
              <IconDrag className="text-grey" />
            </div>
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default TutorialItem;
