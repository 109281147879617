import clsx from "clsx";
import { IconChevronDown, IconChevronUp, IconPlus, IconTrash } from "icons";
import React, { useRef, useState } from "react";

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  icon: any;
  iconClassName?: string;
  numberOfItems?: number;
  ref?: any;
  disabled?: boolean;
  onToggle: () => void;
  childrenHeight?: string;
  childrenContainerClassName?: string;
  menuRef?: any;
  isActive?: boolean;
  deactivateAccordion?: () => void;
  defaultIsOpen?: boolean;
  isOptional?: boolean;
  deactivateCallback?: () => void;
  plusAI?: any;
}

const Accordion = ({
  menuRef,
  childrenContainerClassName,
  ref,
  title,
  children,
  icon,
  disabled,
  onToggle,
  isActive,
  defaultIsOpen,
  deactivateAccordion,
  isOptional,
  deactivateCallback,
  plusAI,
}: AccordionProps) => {
  const accordionChild = useRef<HTMLDivElement>(null);
  const Icon = icon;
  const [isOpen, setIsOpen] = useState(defaultIsOpen ?? false);

  React.useEffect(() => {
    if (!isActive) setIsOpen(false);
    else setIsOpen(true);
  }, [isActive]);

  return (
    <div className="relative flex flex-col border-b border-black-300" style={{ pointerEvents: disabled ? "none" : "auto" }}>
      <div
        className={clsx("flex items-center justify-between cursor-pointer p-3 group/accordion", isOpen ? "" : "hover:bg-black", disabled ? "text-grey pointer-events-none" : "text-white")}
        ref={ref}
        onClick={() => {
          onToggle();
          setIsOpen((prev) => !prev);
        }}
      >
        <div className="flex items-center gap-2.5">
          <Icon
            className={clsx(
              "w-[18px] h-[18px]",
              isActive ? "" : "group-hover/accordion:text-trueBlack dark:group-hover/accordion:text-trueWhite",
              isActive ? "text-trueBlack dark:text-green" : "text-grey"
            )}
          />
          <h6 className="text-h7">{title}</h6>
          {/* <Tooltip content={""} className="hidden group-hover/accordion:flex">
            <IconHelp className="w-4 h-4 text-green" />
          </Tooltip> */}
        </div>
        <div className="flex items-center gap-2.5">
          {plusAI}
          {isActive ? (
            <div className="flex gap-[5px]">
              {isOptional && (
                <IconTrash
                  className="w-4 h-4 text-grey hover:text-white"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (deactivateAccordion) deactivateAccordion();
                    if (deactivateCallback) deactivateCallback();
                    setIsOpen(false);
                  }}
                />
              )}
              {isOpen ? <IconChevronUp className="w-[18px] h-[18px]" /> : <IconChevronDown className="w-[18px] h-[18px]" />}
            </div>
          ) : isOpen ? (
            <IconChevronUp className="w-[18px] h-[18px]" />
          ) : (
            <IconPlus className="w-[18px] h-[18px]" />
          )}
        </div>
      </div>
      <div
        ref={accordionChild}
        className={clsx(!isOpen ? "overflow-hidden" : childrenContainerClassName !== "" ? childrenContainerClassName : "", "flex ")}
        style={{
          maxHeight: isOpen ? "100%" : "0px",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
