/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import DownloadButton from "components/DownloadButton";
import Like from "components/Like";
import { IconBack, IconClose, IconCopy, IconDesign, IconEraserMinus, IconEraserPlus, IconHanger, IconImage, IconNext, IconPattern, IconZoom } from "icons";
import clsx from "clsx";
import "./HistoryCarousel.css";
import LazyImg from "components/LazyImg";
import { useDesignHistoryContext } from "../DesignHistoryContext";
import EditButton from "components/EditButton";
import CopyButton from "components/CopyButton";
import Bookmark from "components/Bookmark";
import ShareButton from "components/ShareButton";
import ShareMenu from "components/ShareMenu";
import Button from "components/Button";
import Tab from "components/Tab";
import { useLocation, useNavigate } from "react-router-dom";
import trainService from "api/train/train.service";
import { AnalyticsEvent, removeBackslash } from "utils";
import ImagePopup from "components/ImagePopup";
import designService from "api/design/design.service";
import { useDiscoverContext } from "pages/DiscoverPage/DiscoverContext";

const KEYS = ["Escape", "ArrowUp", "ArrowLeft", "ArrowDown", "ArrowRight"];

const UploadedImage = ({ title, image }: any) => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (!showPopup) document.body.style.overflow = "hidden";
  }, [showPopup]);

  return image ? (
    <div>
      <ImagePopup image={image} show={showPopup} onClose={() => setShowPopup(false)} />

      <div className="flex flex-col gap-1 w-1/2">
        <span className="text-h7 text-white">{title}</span>
        <div className="relative group overflow-hidden" onClick={() => setShowPopup(true)}>
          <img className="aspect-square object-cover rounded" src={image} />
          <div className="hidden group-hover:flex-center cursor-pointer absolute top-0 w-full h-full bg-opacity-50 bg-black z-10">
            <IconZoom className="text-white" />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

const HistoryCarousel = ({ designIndex = 0, onClose, designsFromHomePage, isDiscover }: any) => {
  const navigate = useNavigate();
  const { designs } = useDesignHistoryContext();
  const { discoverItems } = useDiscoverContext();
  const [selectedImageIndex, setselectedImageIndex] = useState<number>(0);
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [copyPromptData, setCopyPromptData] = useState("");
  const [trainings, setTrainings] = useState([]);
  const _designs = designsFromHomePage ? designsFromHomePage : designs;
  const [designData, setDesignData] = useState<any>(null);
  const location = useLocation();

  function onNext() {
    if (!designData) return;

    if (selectedImageIndex + 1 <= designData?.images.length - 1) setselectedImageIndex((prev) => prev + 1);
  }
  function onBack() {
    setselectedImageIndex((prev) => {
      if (prev - 1 >= 0) {
        return prev - 1;
      }

      return prev;
    });
  }

  React.useEffect(() => {
    trainService.getTrains({ limit: 5000, page: 1 }).then((res) => {
      setTrainings(res.data);
    });
  }, []);

  React.useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";

    const handleEscapeKeyPress = (event: KeyboardEvent) => {
      if (!KEYS.includes(event.key)) return;

      switch (event.key) {
        case "Escape":
          onClose();
          break;
        case "ArrowUp":
        case "ArrowLeft":
          onBack();
          break;
        case "ArrowDown":
        case "ArrowRight":
          onNext();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleEscapeKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
      document.body.style.overflow = originalOverflow;
    };
  }, [designData, selectedImageIndex]);

  useEffect(() => {
    const element = document.getElementById(`CarouselItem_${selectedImageIndex}_`);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [selectedImageIndex]);

  useEffect(() => {
    if (isDiscover) {
      designService.getDesign(discoverItems ? discoverItems[designIndex].id : designsFromHomePage[designIndex].id).then((res) => setDesignData(res.responseData));
    } else designService.getDesign(_designs[designIndex].id).then((res) => setDesignData(res.responseData));
  }, []);

  const PromptSection = () => {
    function RenderPrompts() {
      switch (selectedTab) {
        case 0: {
          setCopyPromptData(designData?.fashion_prompt);

          return designData?.fashion_prompt_array.map((item: any, index: number) => (
            <div className="flex-center py-[3px] px-[5px] text-h7 text-white rounded-[3px] bg-black-300" key={`FashionPromptItem_${index}`}>
              {item}
            </div>
          ));
        }
        case 1: {
          setCopyPromptData(designData?.model_prompt);

          return designData?.model_prompt_array.map((item: any, index: number) => (
            <div className="flex-center py-[3px] px-[5px] text-h7 text-white rounded-[3px] bg-black-300" key={`ModelPromptItem_${index}`}>
              {item}
            </div>
          ));
        }
        case 2: {
          setCopyPromptData(designData?.background_prompt);

          return designData?.background_prompt_array.map((item: any, index: number) => (
            <div className="flex-center py-[3px] px-[5px] text-h7 text-white rounded-[3px] bg-black-300" key={`BackgroundPromptItem_${index}`}>
              {item}
            </div>
          ));
        }
        default:
          break;
      }
    }

    return (
      <>
        <div className="flex flex-grow  gap-2 flex-wrap p-2.5 border border-black-300 rounded-[5px] max-h-[200px] overflow-y-scroll no-scrollbar">{RenderPrompts()}</div>
        <CopyButton copyData={copyPromptData} disabled={copyPromptData === ""} className="self-end h-[18px] text-h7 text-grey hover:text-white disabled:pointer-events-none">
          <IconCopy className="w-4 h-4" /> Copy
        </CopyButton>
      </>
    );
  };

  const currentTrainings = React.useMemo(() => {
    const trainingIds = designData?.style_prompts[0].trains.filter((item: any) => item.train_id !== "prompt"); //removing 'prompt' since it is default

    return trainingIds ? trainingIds.map((item: any) => trainings.find((training: any) => item.train_id === training.id)) : [];
  }, [trainings, designData, selectedImageIndex]);

  return (
    <div className="fixed flex z-50 w-full bg-black" style={{ height: "calc(100vh - 56px)", width: "calc(100vw - 220px)" }}>
      <div className="flex flex-col w-full">
        <div className="flex h-full">
          <ShareMenu show={showShareMenu} onClose={() => setShowShareMenu(false)} />
          <div className="flex items-center w-full justify-between p-10">
            <div className="cursor-pointer mr-full flex-center w-fit h-fit p-2 bg-black-300 rounded text-grey hover:text-white" onClick={onBack}>
              <IconBack className="w-5 h-5 flex-shrink-0" />
            </div>
            <div className="relative h-full">
              <img src={designData?.images[selectedImageIndex].path} className="rounded-xl max-w-full max-h-full" />
              <div className="absolute flex gap-2.5 top-2.5 left-2.5 z-50">
                <EditButton
                  className="btn-primary w-[26px] h-[26px]"
                  design_id={designData?.design_id}
                  onClick={() => {
                    AnalyticsEvent("design_history_carousel_image_edit", "", "click");
                  }}
                />
              </div>
              <div className="absolute flex flex-col gap-2 top-2.5 right-2.5 z-50">
                <Like
                  location={location.pathname === "/" ? "homepage_design_carousel_image" : removeBackslash(location.pathname) + "_design_carousel_image"}
                  isLiked={designData?.is_liked}
                  object_id={designData?.image_id}
                  object_type={"image"}
                  iconClassName="w-4 h-4"
                />
                <Bookmark
                  imageId={designData?.image_id}
                  designId={designData?.id}
                  iconClassName="w-4 h-4"
                  onClick={() => {
                    AnalyticsEvent("design_history_carousel_image_save_collection", "", "click");
                  }}
                />
                {/* {!onSharedWithMePage && <ShareButton onShowShareMenu={() => setShowShareMenu(true)} />} */}
                <DownloadButton
                  imageId={discoverItems ? discoverItems[selectedImageIndex]?.image_id : designData ? designData.images[selectedImageIndex]?.id : null}
                  designId={discoverItems ? discoverItems[selectedImageIndex]?.id : designData ? designData.design_id : null}
                  onClick={() => {
                    AnalyticsEvent("design_history_carousel_image_download", "", "click");
                  }}
                />
              </div>
            </div>
            <div className="cursor-pointer flex-center w-fit h-fit p-2 bg-black-300 rounded text-grey hover:text-white" onClick={onNext}>
              <IconNext className="w-5 h-5 flex-shrink-0" />
            </div>
          </div>

          <div className="flex flex-col max-w-[300px] w-full h-full border-x bg-black-100 border-b border-black-300">
            <div className="flex items-center justify-between text-white p-2 bg-black-300">
              <span className="flex items-center gap-[5px] text-headline uppercase">
                {designData?.design_type === "design" ? (
                  <>
                    <IconHanger className="w-[14px] h-[14px]" /> style
                  </>
                ) : (
                  <>
                    <IconPattern className="w-[14px] h-[14px]" /> pattern
                  </>
                )}
              </span>
              <div className="cursor-pointer flex-center w-5 h-5 bg-black rounded-full" onClick={onClose}>
                <IconClose />
              </div>
            </div>

            <div className="flex flex-col h-full gap-[15px] p-5 overflow-scroll no-scrollbar">
              <div className="flex flex-col gap-2.5">
                <div className="flex justify-between items-center">
                  <span className="text-h7 text-white">Prompt</span>
                </div>
                <Tab initTab={0} onChange={(value: any) => setSelectedTab(value)}>
                  <Tab.Item id={0}>
                    <div className="flex-center gap-[5px] w-full">Fashion</div>
                  </Tab.Item>
                  <Tab.Item id={1}>
                    <div className="flex-center gap-[5px] w-full">Model</div>
                  </Tab.Item>
                  <Tab.Item id={2}>
                    <div className="flex-center gap-[5px] w-full">Background</div>
                  </Tab.Item>
                </Tab>

                <PromptSection />
              </div>

              <div className="flex flex-col gap-2.5 pt-[15px] border-t border-black-300">
                <span className="text-h7 text-white">Training Models</span>
                <div className="flex flex-grow  gap-2 flex-wrap p-2.5 border border-black-300 rounded-[5px]">
                  {currentTrainings.map((item: any, index: number) => (
                    <div className="flex-center py-[3px] px-[5px] text-h7 text-white rounded-[3px] bg-black-300" key={`BackgroundPromptItem_${index}`}>
                      {item?.name}
                    </div>
                  ))}
                </div>
              </div>

              {designData?.design_by && (
                <div className="flex flex-col gap-2.5 pt-[15px] border-t border-black-300">
                  <span className="text-h7 text-white">Design by</span>
                  <div className="flex items-center gap-2 p-2.5 border border-black-300 rounded-[5px]">
                    <div className="flex-center w-5 h-5 bg-black-300 rounded-full uppercase text-white text-[10px]">{designData?.design_by ? designData?.design_by[0] : ""}</div>
                    <span className="text-h7 text-white">{designData?.design_by}</span>
                  </div>
                </div>
              )}

              {(designData?.set_images.reference_image || designData?.set_images.sketch_image || designData?.set_images.position_image || designData?.set_images.fabric_image) && (
                <div className="flex gap-4 pt-[15px] border-t border-black-300">
                  <UploadedImage title="Reference Image" image={designData?.set_images.reference_image} />
                  <UploadedImage title="Sketch Image" image={designData?.set_images.sketch_image} />
                  <UploadedImage title="Pose Image" image={designData?.set_images.position_image} />
                  <UploadedImage title="Fabric Image" image={designData?.set_images.fabric_image} />
                </div>
              )}
            </div>
            <div className="flex w-full sticky bottom-0 p-2.5 bg-black border-t border-black-300">
              <Button
                className="btn-primary w-full"
                onClick={() => {
                  AnalyticsEvent("design_history_carousel_image_open_in_editor_button", "", "click");
                  navigate(`/design/${designData?.design_id}`);
                }}
              >
                <IconDesign className="w-5 h-5" />
                Open in Editor
              </Button>
            </div>
          </div>

          <div className="flex justify-center p-2.5 bg-black-100">
            <div className="flex max-h-full w-[120px] flex-col gap-2.5">
              {designData?.images.map((item: any, index: number) => (
                <LazyImg
                  src={item.path}
                  id={`CarouselItem_${index}_`}
                  key={`CarouselItem_${item.id}_${index}`}
                  onClick={() => setselectedImageIndex(index)}
                  className={clsx("cursor-pointer w-[120px] h-[120px] object-cover object-center rounded-md", selectedImageIndex === index ? "border border-green" : "opacity-50")}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryCarousel;
