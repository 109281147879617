import Button from "components/Button";
import Modal from "components/Modal";
import React from "react";

const DeleteModal = ({ onClose, show, trainId, onDelete }: any) => {
  async function handleDelete() {
    await onDelete(trainId);
    onClose();
  }

  return (
    <Modal onClose={onClose} show={show} bodyClassName="!rounded-[5px] !border-none">
      <div className="flex flex-col bg-black-100 bg-opacity-30 w-[300px]">
        <div className="p-2 bg-black-300 text-h6 text-white rounded-t-[5px]">Delete</div>
        <div className="flex flex-col gap-3 w-full bg-black p-3">
          <span className="text-bodySm text-white">Are you sure that you want to delete this training? This can`t be undone.</span>
        </div>
        <div className="flex bg-black-100 gap-2.5 p-2.5 border border-black-300 rounded-b-[5px]">
          <Button className="btn-secondary w-full" onClick={onClose}>
            Cancel
          </Button>
          <Button className="btn-warning w-full" onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
