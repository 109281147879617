import React, { useEffect } from "react";
import clsx from "clsx";

const PlusAI = ({ isActive, handleClick }: any) => {
  const [_isActive, setisActive] = React.useState(false);

  useEffect(() => {
    setisActive(isActive);
  }, [isActive]);

  return (
    <div className="flex relative">
      <div
        className={clsx(
          "peer w-7 h-7 cursor-pointer text-headline flex-center border rounded-[3px] px-[5px] py-[2px] border-black-300",
          _isActive ? "text-green-dark dark:text-green bg-black " : "text-grey"
        )}
        onClick={(e) => {
          e.stopPropagation();
          handleClick();
          setisActive((prev) => !prev);
        }}
      >
        +AI
      </div>
      <div className="peer-hover:flex absolute top-8 hidden z-50 hover:flex flex-col w-[268px] border border-black-300 rounded-[5px]" style={{ right: "calc(100% - 28px)" }}>
        <div className="flex justify-between text-headline bg-black-300 p-2 rounded-t-[4px]">
          <span className="text-blackGreen">PLUS AI</span>
          {_isActive ? <span className="text-green-dark dark:text-green">ACTIVE</span> : <span className="text-grey">DEACTIVE</span>}
        </div>
        <span className="text-bodySm text-white bg-black-100 px-2.5 py-3 rounded-b-[5px]">Use +AI to enhance prompts in any language for better results</span>
      </div>
    </div>
  );
};

export default PlusAI;
