import React from "react";
import clsx from "clsx";

const AnimatedGradientBorder = ({ children, isActive = false, inActiveBorder = "" }: any) => {
  return (
    <div className={clsx("flex w-full min-h-full rounded-md", !isActive ? inActiveBorder : "")}>
      {/* Animated border container */}
      <div
        className={clsx(
          "animated-border relative flex w-full h-fit rounded-[5px]",
          isActive ? "bg-border-animation bg-gradient-to-b from-[#2b2b2b] via-green-dark dark:via-green to-[#2b2b2b] bg-size-200" : "bg-position-initial",
          !isActive ? "inactive" : ""
        )}
      >
        {/* Content container */}
        <div className={clsx("relative w-full h-fit bg-black-200 rounded-[5px]", isActive ? "m-[1px]" : "")}>{children}</div>
      </div>
    </div>
  );
};

export default AnimatedGradientBorder;
