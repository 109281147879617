import React from "react";
import { IconCheck } from "icons";

interface Props {
  data: any;
  headers: any[];
  objectKey: string;
}

const TableComponent: React.FC<Props> = ({ data, headers, objectKey }) => {
  return (
    <div className="w-full">
      <table className="w-full text-trueBlack border-separate border-spacing-4">
        <thead></thead>
        <tbody>
          {headers.map((header, index) => (
            <tr key={header} className="">
              <td className="relative text-bodyMd block py-2.5 whitespace-nowrap min-w-[153px]">{header.name}</td>
              {data.map((item: any, idx: number) => (
                <td key={idx} className="text-bodyMd text-center text-trueBlack w-[264px] p-2.5">
                  {item[objectKey][index].value === "true" ? (
                    <div className="flex-center">
                      <IconCheck />
                    </div>
                  ) : item[objectKey][index].value === "false" ? (
                    "-"
                  ) : (
                    <>{item[objectKey][index].value}</>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
