import React from "react";
import { IconClose } from "icons";
import { ImageFeatureAnnouncement1 } from "images";
import Button from "components/Button";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { DesignType } from "pages/DesignPage/DesignProvider";
import { AnalyticsEvent } from "utils";

export const features = [
  {
    title: "Turn Your Sketches into Stunning Designs! 🎨✨",
    description: () => (
      <span className="text-bodyMd">
        Our improved <span className="text-green">Sketch-to-Design</span> feature now transforms your ideas into high-quality fashion visuals with even more accuracy and style. Simply upload your
        sketch and watch it come to life!
      </span>
    ),
    image: ImageFeatureAnnouncement1,
    button: ({ navigateFunc, callback }: { navigateFunc: NavigateFunction; callback?: () => void }) => (
      <Button
        className="btn-primary text-trueBlack"
        onClick={() => {
          AnalyticsEvent("New_Feature_Pop_up", "", "click");

          navigateFunc("/design", { state: { designType: DesignType.design } });
          if (callback) callback();
        }}
      >
        Try Now
      </Button>
    ),
  },
];

const WhatsNew = ({ show, onClose }: any) => {
  const navigate = useNavigate();

  const FeatureItem = ({ title, description, image, button }: any) => {
    return (
      <div className="flex flex-col gap-3 p-3 border border-black-300 rounded">
        <div className="">
          <img src={image} className="" />
        </div>

        <div className="flex items-center justify-between">
          <h6 className="text-h6 text-white">{title}</h6>
        </div>

        <span className="text-bodySm text-grey">{description}</span>

        {button && button({ navigateFunc: navigate })}
      </div>
    );
  };

  return (
    <div
      className="bg-black-100 border-l border-black-300  no-scrollbar"
      style={{
        position: "absolute",
        height: "calc(100vh - 86px)",
        width: "320px",
        top: "86px",
        right: 0,
        // transform: `translateX(${show ? "0" : "100%"})`,
        // transition: "transform 0.3s ease-in-out",
        overflowY: "auto",
        visibility: show ? "visible" : "hidden",
      }}
    >
      <div className="flex justify-between items-center p-2 bg-black">
        <span className="text-headline uppercase text-white">what`s new?</span>
        <IconClose className="cursor-pointer text-white w-[14px] h-[14px]" onClick={onClose} />
      </div>

      <div className="flex flex-col p-2.5 gap-4">
        {features.map((item, index) => (
          <FeatureItem key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

export default WhatsNew;
