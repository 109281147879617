import Button from "components/Button";
import Modal from "components/Modal";
import { ImageUpdateModal1, ImageUpdateModal3 } from "images";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PATHS } from "router/config/paths";
import { useAppSelector } from "store";
import { hideUpdateModal } from "store/commonSlice";
import { getClearPath } from "utils";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import { nanoid } from "@reduxjs/toolkit";
import clsx from "clsx";

const INITIAL_SLIDE = 0;

const UpdateModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showUpdateModal } = useAppSelector((state) => state.common);
  const [activeIndex, setActiveIndex] = React.useState(INITIAL_SLIDE);
  const swiperRef = React.useRef<any>(null);

  React.useEffect(() => {
    setActiveIndex(INITIAL_SLIDE);
  }, [showUpdateModal]);

  const handleDotClick = (index: number) => {
    setActiveIndex(index);
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
    }
  };

  const items = [
    {
      img: ImageUpdateModal1,
      title: "Brand New Mannequin Advanced Photoshooting",
      descriptionLines: [
        "Easily change the model wearing your garments with our advanced mannequin photoshooting feature.",
        "Retain every detail of your garments while creating stunning, high-quality visuals.",
        "Perfect for professional marketing, e-commerce, and catalog shots that capture attention and leave a lasting impression.",
      ],
    },
    // {
    //   img: ImageUpdateModal2,
    //   title: "Enhance Your Creations with Ghost Photoshooting",
    //   descriptionLines: [
    //     "Transform traditional ghost shots into striking visuals that highlight the fit and structure of your designs using lifelike AI models.",
    //     "Maintain a clean and professional aesthetic, ideal for showcasing product details in catalogs or presentations.",
    //     "Save time and money while creating sleek, elevated photos that stand out.",
    //   ],
    // },
    {
      img: ImageUpdateModal3,
      title: "Experience Our Upgraded Try-On Technology",
      descriptionLines: [
        "Apply your designs to a wide range of AI models from our expanded catalog, offering diverse looks and styles to match your creative vision.",
        "Perfect for rapid design visualization, this feature helps you see your garments on lifelike, dynamic models in minutes.",
        "Ideal for experimenting with ideas or quickly showcasing designs.",
      ],
    },
  ];

  function onClose() {
    dispatch(hideUpdateModal());
  }

  return (
    <Modal onClose={onClose} show={showUpdateModal} bodyClassName="bg-black-100 !max-w-[480px] !border-black-300 z-50 h-full overflow-hidden overflow-y-auto no-scrollbar" className="!z-[9999]">
      <div className="flex flex-col gap-[23px] p-6 overflow-y-scroll">
        <h2 className="text-h2 text-white">New Feature Update!</h2>

        <Swiper
          slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={15}
          // pagination={{
          //   clickable: true,
          // }}
          grabCursor={true}
          onSlideChange={(swiper: SwiperClass) => {
            setActiveIndex(swiper.activeIndex);
          }}
          modules={[Pagination]}
          initialSlide={INITIAL_SLIDE}
          onSwiper={(swiper: any) => {
            swiperRef.current = swiper;
          }}
        >
          {items?.map((item: any) => {
            return (
              <SwiperSlide key={nanoid()}>
                <div className="flex flex-col gap-5">
                  <img src={item.img} />
                  <h4 className="text-h4 text-white">{item.title}</h4>
                  <div className="flex flex-col gap-5">
                    {item.descriptionLines.map((line: string) => (
                      <span key={nanoid()} className="text-bodyMd text-white">
                        {line}
                      </span>
                    ))}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
          <div className="flex-center items-center w-full gap-[5px]">
            {items.map((_, index) => (
              <button key={nanoid()} className={clsx("rounded-full h-2 w-2", activeIndex === index ? "bg-green" : "bg-grey")} onClick={() => handleDotClick(index)} />
            ))}
          </div>
        </Swiper>

        <div className="flex flex-col gap-2">
          <Button
            className="btn-primary"
            onClick={() => {
              onClose();

              navigate(getClearPath(PATHS.PHOTOSHOOT));
            }}
          >
            Try Now
          </Button>
          <Button className="btn-secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateModal;
