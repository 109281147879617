import userService from "api/user/user.service";
import clsx from "clsx";
import Button from "components/Button/Button";
import Tooltip from "components/Tooltip";
import { IconLiked, Iconlike } from "icons";
import React, { useEffect, useState } from "react";
import { EventLike, EventUnlike } from "utils/events";

interface LikeProps {
  location: string;
  object_type: string;
  object_id: string;
  iconClassName?: string;
  onClick?: () => void;
  callback?: () => void;
  isLiked: boolean;
  position?: "bottom" | "top" | "left" | "right" | null;
}

const Like = ({ location, object_type, object_id, iconClassName, callback, onClick, isLiked, position = "left" }: LikeProps) => {
  const [_isLiked, setIsLiked] = useState(isLiked);

  useEffect(() => {
    setIsLiked(isLiked);
  }, [isLiked]);

  function handleOnClick() {
    if (_isLiked) {
      EventUnlike(location);
    } else {
      EventLike(location);
    }

    if (onClick) onClick();
    userService.like({ object_type, object_id }).then(() => {
      setIsLiked((prev) => !prev);
      if (callback) callback();
    });
  }

  return (
    <Tooltip content="Like" position={position}>
      <Button className={clsx("flex-center p-2 hover:bg-pink w-[26px] h-[26px] rounded-[3px] border border-black-200 dark:border-none", _isLiked ? "bg-pink" : "bg-trueWhite")} onClick={handleOnClick}>
        {_isLiked ? <IconLiked className={clsx(iconClassName, "flex-shrink-0")} /> : <Iconlike className={clsx(iconClassName, "flex-shrink-0")} />}
      </Button>
    </Tooltip>
  );
};

export default Like;
