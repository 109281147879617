/* eslint-disable react/no-unescaped-entities */
import React, { useRef, useState } from "react";
import { IconCheck, IconChevronDown, IconChevronUp, IconCollection, IconDesign, IconPlay, IconShared, IconTrain } from "icons";
import clsx from "clsx";
import Button from "components/Button";
import { useDispatch } from "react-redux";
import { setTutorial } from "store/commonSlice";
import { TutorialType } from "components/Tutorial/components/TutorialItem";
import { DesignTutorialData, EditImageTutorialData, TrainTutorialData } from "components/Tutorial/TutorialData";
import { TUTORIAL_DESIGN_COMPLETED, TUTORIAL_EDIT_COMPLETED, TUTORIAL_TRAIN_COMPLETED, useLocalStorage } from "hooks/useLocalStorage";

const iconClassName = "w-5 h-5";

const CreateDropdown = ({ onClick }: any) => {
  const [showBtn, setShowBtn] = useState(true);

  return (
    <div className="flex flex-col gap-[15px] pl-[30px]">
      <span className="text-bodyMd text-grey">Unleash your creativity. Design new fashion styles and patterns with Refabric’s powerful AI.</span>
      {showBtn && (
        <div className="flex">
          <Button
            className="btn-primary-small"
            onClick={() => {
              setShowBtn(false);
              onClick();
            }}
          >
            <IconPlay className="w-[18px] h-[18px]" /> Get Started
          </Button>
        </div>
      )}
    </div>
  );
};

const TrainDropdown = ({ onClick }: any) => {
  const [showBtn, setShowBtn] = useState(true);

  return (
    <div className="flex flex-col gap-[15px] pl-[30px]">
      <span className="text-bodyMd text-grey">Kickstart your journey by training a AI model. It's your first step to bespoke fashion design.</span>
      {showBtn && (
        <div className="flex">
          <Button
            className="btn-primary-small"
            onClick={() => {
              setShowBtn(false);
              onClick();
            }}
          >
            <IconPlay className="w-[18px] h-[18px]" /> Get Started
          </Button>
        </div>
      )}
    </div>
  );
};

const EditDropdown = ({ onClick }: any) => {
  const [showBtn, setShowBtn] = useState(true);

  return (
    <div className="flex flex-col gap-[15px] pl-[30px] w-full">
      <span className="text-bodyMd text-grey">Assemble your designs into a stunning collection.</span>
      {showBtn && (
        <div className="flex">
          <Button
            className="btn-primary-small"
            onClick={() => {
              setShowBtn(false);
              onClick();
            }}
          >
            <IconPlay className="w-[18px] h-[18px]" /> Get Started
          </Button>
        </div>
      )}
    </div>
  );
};

const OnBoarding = () => {
  const dispatch = useDispatch();

  const items = [
    { icon: <IconShared className={clsx(iconClassName, "text-purple")} />, title: "You completed your account details", time: "", isCompleted: true },
    {
      icon: <IconDesign className={clsx(iconClassName, "text-pink")} />,
      title: "Create a new design",
      time: "~5 min",
      dropdown: <CreateDropdown onClick={() => dispatch(setTutorial({ show: true, type: TutorialType.Design, data: DesignTutorialData }))} />,
      isCompleted: useLocalStorage().getItem(TUTORIAL_DESIGN_COMPLETED),
    },
    {
      icon: <IconTrain className={clsx(iconClassName, "text-green")} />,
      title: "Train your first model",
      time: "~5 min",
      dropdown: <TrainDropdown onClick={() => dispatch(setTutorial({ show: true, type: TutorialType.Train, data: TrainTutorialData }))} />,
      isCompleted: useLocalStorage().getItem(TUTORIAL_TRAIN_COMPLETED),
    },
    {
      icon: <IconCollection className={clsx(iconClassName, "text-orange")} />,
      title: "Edit Image",
      time: "~2 min",
      dropdown: <EditDropdown onClick={() => dispatch(setTutorial({ show: true, type: TutorialType.EditImage, data: EditImageTutorialData }))} />,
      isCompleted: useLocalStorage().getItem(TUTORIAL_EDIT_COMPLETED),
    },
  ];

  const AccordionItem = ({ title, icon, time, dropdown, isCompleted, index }: any) => {
    const Icon = icon;
    const accordionChild = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="flex flex-col w-full">
        <div className="flex justify-between">
          <div className="flex items-center gap-2.5 text-white text-h5">
            {Icon} {title} <span className="text-bodySm text-grey">{time}</span>
          </div>
          <div className="flex items-center w-fit gap-2.5">
            <div className={clsx("flex-center w-[30px] h-[30px]  rounded-full", isCompleted ? "bg-black-300" : "border border-black-300")}>
              <IconCheck className={clsx(isCompleted ? "text-blackGreen" : "text-grey")} />
            </div>
            {index !== 0 &&
              (isOpen ? (
                <IconChevronUp className="cursor-pointer text-white" onClick={() => setIsOpen((prev) => !prev)} />
              ) : (
                <IconChevronDown className="cursor-pointer text-white" onClick={() => setIsOpen((prev) => !prev)} />
              ))}
          </div>
        </div>
        <div ref={accordionChild} className={clsx("accordion-element overflow-hidden", isOpen ? "mt-[15px]" : "")} style={{ maxHeight: isOpen ? accordionChild.current?.scrollHeight + "px" : "" }}>
          {dropdown ? dropdown : <></>}
        </div>
        {items.length - 1 !== index && <div className="h-[1px] bg-black-300 w-full mt-[15px]" />}
      </div>
    );
  };

  return (
    <div className="flex w-full gap-10">
      <div className="flex flex-col gap-5 w-[40%]">
        <div className="flex flex-col gap-2.5 w-full">
          <h2 className="text-h2 text-white">
            Welcome To <span className="dark:text-green text-purple">Refabric!</span>
          </h2>
          <span className="text-bodyMd text-grey">Let’s take a look at a few steps chosen for you to get you started.</span>
        </div>
      </div>
      <div className="flex flex-col h-fit gap-[15px] w-[60%] bg-black-100 p-5 rounded-[10px]">
        {items.map((item: any, index: number) => (
          <AccordionItem key={`onboarding_accordion_${index}`} title={item.title} icon={item.icon} time={item.time} dropdown={item.dropdown} isCompleted={item.isCompleted} index={index} />
        ))}
      </div>
    </div>
  );
};

export default OnBoarding;
