import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import Dropdown from "components/Dropdown";
import DropdownItem from "components/Dropdown/components/DropdownItem";
import { useClickOutside } from "hooks/useClickOutside";
import { IconChevronDown } from "icons";
import { MAX_SELECTABLE_TRAINING_COUNT } from "./DesignLeftMenu";
import { AnalyticsEvent } from "utils";
import { useAppSelector } from "store";
import userService from "api/user/user.service";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/snackbarSlice";
import { ScreenType } from "components/WarningSnackbar/WarningSnackbar";

const TrainingSelect = ({ Footer, trainings, selectedTrainings, setSelectedTrainings }: any) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [filteredTrainings, setFilteredTrainings] = useState(trainings ?? []);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { user } = useAppSelector((state) => state.common);
  const [userInfo, setUserInfo] = useState<any>(null);

  useEffect(() => {
    if (trainings) setFilteredTrainings(trainings);
  }, [trainings]);

  React.useEffect(() => {
    if (user.id === "" || user.id === undefined) return;

    userService
      .getUserDetail({
        user_id: user.id,
      })
      .then((res) => {
        setUserInfo(res.responseData);
      });
  }, [user.id]);

  useClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  function onSelect(item: any) {
    const isPremium = userInfo?.package_detail.some((item: any) => item.name.toLowerCase() !== "design studio");
    if (isPremium) {
      dispatch(showSnackbar({ type: 402, screen: ScreenType.Train }));

      return;
    }

    AnalyticsEvent("design_screen_select_training", "", "click");
    // if (selectedTrainings.length === MAX_SELECTABLE_TRAINING_COUNT) return;

    const newItem = { train_id: item.id, name: item.name, keyword: item.keyword };
    // setSelectedTrainings((prev: any) => [...prev, newItem]);
    setSelectedTrainings([newItem]);
  }

  function onRemove(id: string) {
    const newArr = selectedTrainings.filter((item: any) => item.train_id !== id);
    setSelectedTrainings(newArr);
  }

  function trainingSearch(searchInput: string) {
    if (searchInput === "") setFilteredTrainings(trainings);
    const lowerCaseSearchInput = searchInput.toLowerCase();
    const _filteredTrainings = trainings.filter((item: any) => item.name.toLowerCase().includes(lowerCaseSearchInput));
    setFilteredTrainings(_filteredTrainings);
  }

  // Separate the "default" item from the trainings and filteredTrainings lists
  const defaultItem = trainings.find((item: any) => item.id === "default");

  // Filter out the "default" item from the selected and unselected lists
  // !! change trainings to filteredTrainings if you dont want to show selected ones when filtered
  const selectedItems = trainings.filter((item: any) => item.id !== "default" && selectedTrainings.some((_item: any) => _item.train_id === item.id));
  const unselectedItems = filteredTrainings.filter((item: any) => item.id !== "default" && !selectedTrainings.some((_item: any) => _item.train_id === item.id));

  // Combine selected and unselected items
  const allItems = selectedItems.concat(unselectedItems);

  // Add the "default" item back to the beginning of the list, if it exists
  if (defaultItem) {
    allItems.unshift(defaultItem);
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className={clsx("cursor-pointer overflow-visible flex flex-col w-full text-white border border-black-300 rounded-[5px]")}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen((prev) => !prev);
        }}
      >
        <div className="flex !h-[36px]">
          <span className={clsx("text-bodySm text-overflow py-2.5 px-2.5", selectedTrainings.length === 0 ? "text-grey" : "")}>
            {selectedTrainings.length === 0
              ? "Please Select"
              : selectedTrainings.length > 1
              ? selectedTrainings.length + " Trainings Selected"
              : selectedTrainings.length === 0
              ? ""
              : selectedTrainings[0].name
              ? selectedTrainings[0].name
              : trainings.find((item: any) => selectedTrainings[0]?.train_id === item.id)?.name}
          </span>
          <div className={clsx("py-2.5 px-2.5 bg-black border-l border-black-300 ", selectedTrainings.length === 0 && trainings.length > 0 ? "rounded-tr-[4px]" : "rounded-r-[4px]")}>
            <IconChevronDown className="w-[18px] h-[18px]" />
          </div>
        </div>
        {/* {selectedTrainings.length === 0 && trainings.length > 0 && (
          <span className="flex text-orange text-bodySm items-center gap-[5px] p-[5px] bg-orange bg-opacity-10 rounded-b border-t border-black-300">
            <IconWarning className="h-[14px] w-[14px] flex-shrink-0" />
            Select at least one training.
          </span>
        )} */}
      </div>
      <Dropdown className={clsx("absolute top-full mt-2 left-0 z-[49]", isOpen ? "" : "hidden")} Footer={Footer} onClose={() => setIsOpen(false)} searchCallBack={trainingSearch}>
        {allItems.map((item: any, index: number) => {
          if (item.status !== 2) return <></>;

          const notDesignStudioUser = userInfo?.package_detail.some((item: any) => item.name.toLowerCase() !== "design studio");
          const isPremium = item.id === "default" ? false : notDesignStudioUser;

          return (
            <DropdownItem
              isSelected={selectedTrainings.filter((_item: any) => _item.train_id === item.id).length > 0}
              disabled={
                (notDesignStudioUser && item.id === "default") ||
                (selectedTrainings.find((_item: any) => _item.train_id === item.id) === undefined && selectedTrainings.length === MAX_SELECTABLE_TRAINING_COUNT + 1)
              }
              isPremium={isPremium}
              key={item.id + "_" + index}
              title={item.name}
              onSelect={() => onSelect(item)}
              onRemove={() => onRemove(item.id)}
            />
          );
        })}
      </Dropdown>
    </div>
  );
};

export default TrainingSelect;
