import React, { useRef, useState } from "react";
import clsx from "clsx";
import useS3 from "hooks/useS3";
import { IconTrash, IconUpload, IconWarning } from "icons";
import ImagePopup from "components/ImagePopup";
import useDragUpload from "hooks/useDragUpload";
import { useDispatch } from "react-redux";
import { setSnackbar } from "store/commonSlice";
import { EventUploadLimitError } from "utils/events";
import { ImageFallback } from "images";
import { MIN_WIDTH_HEIGHT_DEFAULT } from "utils/constants";
import { AnalyticsEvent } from "utils";

interface ImageValidationResult {
  file: File;
  isValid: boolean;
  error?: string;
  dimensions?: {
    width: number;
    height: number;
  };
}

interface IUploadBox {
  accept?: string;
  multiple?: boolean;
  location: string;
  value?: any;
  imageShowable?: boolean;
  isRemovable?: boolean;
  icon?: any;
  title?: string;
  text?: string;
  callback: any;
  className?: string;
  needsReset?: boolean;
  uploadedFileNames?: string[];
  removeCallback?: any;
  children?: any;
  minWidth?: number;
  minHeight?: number;
  imageErrorCallback?: () => void;
  isLimited?: boolean;
  noDimensionCheck?: boolean;
  childContainerClassName?: string;
}

const UploadBox = ({
  accept = "image/png, image/jpg, image/jpeg",
  multiple = false,
  location,
  value,
  imageShowable = true,
  isRemovable = true,
  icon = IconUpload,
  title,
  text,
  callback,
  className,
  removeCallback = () => "",
  uploadedFileNames,
  children,
  minWidth = MIN_WIDTH_HEIGHT_DEFAULT,
  minHeight = MIN_WIDTH_HEIGHT_DEFAULT,
  imageErrorCallback,
  isLimited = true,
  needsReset = false,
  noDimensionCheck = false,
  childContainerClassName = "",
}: IUploadBox) => {
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const { handleDragEnter, handleDragLeave, handleDrop, isDragging } = useDragUpload();
  const [imageError, setImageError] = useState(false);

  const handleImageError = (e: any) => {
    console.log({ e });
    setImageError(true);
    if (imageErrorCallback) imageErrorCallback();
  };

  const { handleUpload } = useS3();
  const Icon = icon;

  const checkImageDimensions = async (files: File[]): Promise<ImageValidationResult[]> => {
    if (minHeight === undefined || minWidth === undefined) return [];

    const validateSingleFile = (file: File): Promise<ImageValidationResult> => {
      return new Promise((resolve) => {
        // if file is not an image, resolve with isValid: true
        if (!file.type.startsWith("image/")) {
          resolve({
            file,
            isValid: true,
          });
        }

        // Check if file type matches any of the accepted types
        const acceptedTypes = accept.split(",").map((type) => type.trim());
        const isAcceptedType = acceptedTypes.some((type) => {
          // Handle wildcards like "image/*"
          if (type.endsWith("/*")) {
            const category = type.split("/")[0];

            return file.type.startsWith(`${category}/`);
          }

          return type === file.type;
        });

        if (!isAcceptedType) {
          resolve({
            file,
            isValid: false,
            error: `File type not accepted.`,
          });

          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target?.result as string;
          img.onload = () => {
            const meetsDimensions = img.width >= minWidth && img.height >= minHeight;

            AnalyticsEvent("Size_Error", "", "click", { panel: location, result: value, size: img.width + "x" + img.height });

            resolve({
              file,
              isValid: meetsDimensions,
              dimensions: { width: img.width, height: img.height },
              error: !meetsDimensions ? `Image must be at least ${minWidth}x${minHeight}px` : undefined,
            });
          };
        };
      });
    };

    return Promise.all(files.map(validateSingleFile));
  };

  const handleIconClick = () => {
    if (uploadedImage !== "") setShowPopup(true);
    else if (inputRef.current) {
      inputRef.current.click();
    }
  };

  function wrapHandleDrop(e: React.DragEvent<HTMLDivElement>, callback: any) {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    const validFileTypes = accept.split(",").map((type) => type.trim());

    for (const file of Array.from(files)) {
      if (!validFileTypes.includes(file.type)) {
        handleDragLeave();
        setError("Invalid file type");

        return;
      }
    }

    handleDrop(e, callback);
  }

  function onRemoveUploaded(e: any) {
    e.stopPropagation();
    if (inputRef.current) inputRef.current.value = "";
    setUploadedImage("");
    callback("");
    if (removeCallback) removeCallback();
  }

  async function onUpload(e: any) {
    setImageError(false);
    setError("");

    const files = e.target.files ?? e.dataTransfer.files;

    if (!files) return;
    if (files.length === 0) return;

    if (uploadedFileNames && uploadedFileNames.includes(files[0].name)) {
      setError("Can't upload same image!");

      return;
    }

    if (isLimited && files[0].size > Number(process.env.REACT_APP_UPLOAD_LIMIT) * 1000000) {
      if (icon && text) {
        EventUploadLimitError(location);
        setError(`File size should be less than ${Number(process.env.REACT_APP_UPLOAD_LIMIT)}MB`);
        dispatch(setSnackbar({ message: `File size should be less than ${Number(process.env.REACT_APP_UPLOAD_LIMIT)}MB`, icon: IconWarning }));
      } else {
        EventUploadLimitError(location);
        dispatch(setSnackbar({ message: `File size should be less than ${Number(process.env.REACT_APP_UPLOAD_LIMIT)}MB`, icon: IconWarning }));
      }

      return;
    }

    try {
      if (isUploading || files?.length === 0) return;
      setIsUploading(true);
      if (files && files.length > 0) {
        const _files = new Array(1);
        _files[0] = files[0];

        // Check image dimensions
        if (!noDimensionCheck) {
          const validationResults = await checkImageDimensions(Array.from(files));
          const invalidFiles = validationResults.filter((result) => !result.isValid);

          if (invalidFiles.length > 0) {
            setError(invalidFiles.map((f) => `${files.length > 1 ? f.file.name + ":" : ""} ${f.error}`).join("\n"));
            setIsUploading(false);

            return;
          }
        }

        const urlArray = await handleUpload({ files: _files, isLimited: isLimited });
        setIsUploading(false);
        setUploadedImage(urlArray[0].Location);
        if (callback) callback(urlArray[0].Location, files[0].name, files[0]);
        if (inputRef.current) inputRef.current.value = "";
        if (needsReset) {
          setUploadedImage("");
        }
      }
    } catch (error) {
      setIsUploading(false);
      setUploadedImage("");
    }
  }

  React.useEffect(() => {
    setUploadedImage("");
    if (inputRef.current) inputRef.current.value = "";
  }, [value]);

  React.useEffect(() => {
    if (value) {
      setUploadedImage(value);
      if (inputRef.current) inputRef.current.defaultValue = value;
    }
  }, [value]);

  return (
    <div
      className={clsx(
        "relative flex-center flex-col px-2.5 h-[120px] cursor-pointer rounded-[5px] border border-black-300 overflow-hidden",
        isDragging ? "border-dashed border-green bg-[#22231D]" : "",
        className
      )}
      onClick={handleIconClick}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => wrapHandleDrop(e, () => onUpload(e))}
    >
      {uploadedImage && imageShowable && <ImagePopup image={uploadedImage} show={showPopup} onClose={() => setShowPopup(false)} />}

      <input ref={inputRef} accept={accept} multiple={multiple} style={{ display: "none" }} type="file" onChange={async (e) => onUpload(e)} />

      {isUploading ? (
        <div className="h-[2px] w-3/4 bg-white bg-opacity-10 rounded-full overflow-hidden">
          <div className="h-full bg-green w-1/2 animate-image-upload" />
        </div>
      ) : uploadedImage !== "" && imageShowable ? (
        <div className="h-full w-full relative">
          <img src={imageError ? ImageFallback : uploadedImage} key={uploadedImage} className="absolute inset-0 w-full h-full object-cover" onError={handleImageError} />
        </div>
      ) : (
        <div className={clsx("default-child", error ? "error" : "", " flex-center flex-col p-2.5 gap-2.5 h-full", childContainerClassName)}>
          {icon && <Icon className="text-white upload-icon" />}
          {title && <h6 className="text-h6 text-white text-center">{title}</h6>}
          {text && <h5 className="text-bodySm text-grey line-clamp-2 text-center">{text}</h5>}
          {error && (
            <div className="flex items-center w-full">
              <IconWarning className="w-4 h-4 flex-shrink-0 text-orange icon-warning" />
              <h5 className="error-text text-bodySm text-orange">{error}</h5>
            </div>
          )}
          {children}
        </div>
      )}

      {uploadedImage !== "" && imageShowable && isRemovable && (
        <div className="absolute top-2.5 right-2.5 z-50">
          <div className="cursor-pointer flex-center h-[26px] w-[26px] bg-white rounded-[3px]" onClick={(e: any) => onRemoveUploaded(e)}>
            <IconTrash className="w-4 h-4 text-black" />
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadBox;
