import React, { ReactNode, useEffect } from "react";
import { useTheme, Theme } from "hooks/useTheme";

interface ThemeProviderProps {
  children: ReactNode;
  theme?: Theme | null;
}

const ThemeProvider = ({ children, theme }: ThemeProviderProps) => {
  const { setTheme, effectiveTheme } = useTheme();

  // Apply the route-specific theme if provided
  useEffect(() => {
    if (theme) {
      // Force the theme for this route
      const root = document.documentElement;

      if (theme === "dark") {
        root.classList.add("dark");
        root.setAttribute("data-theme", "dark");
      } else {
        root.classList.remove("dark");
        root.setAttribute("data-theme", "light");
      }
    }

    // Restore the user's preference when the component unmounts
    return () => {
      if (theme) {
        const savedTheme = (localStorage.getItem("theme") as Theme) || "system";
        setTheme(savedTheme);
      }
    };
  }, [theme, setTheme]);

  return <>{children}</>;
};

export default ThemeProvider;
