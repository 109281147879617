import { IconChevron } from "icons";
import React from "react";

const MenuDropdown = ({ children, text = "" }: any) => {
  const [isOpen, setIsOpen] = React.useState(true);

  function handleOnClick() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="flex flex-col">
      <button className="flex items-center justify-between text-grey hover:text-white" onClick={handleOnClick}>
        <span className="text-headline uppercase px-2 py-2.5">{text}</span>
        <IconChevron className={`w-4 h-4 transition-transform ${isOpen ? "rotate-180" : ""}`} />
      </button>
      {isOpen && children}
    </div>
  );
};

export default MenuDropdown;
