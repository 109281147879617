import React, { useRef, useState } from "react";
import AWS from "aws-sdk";
import { IconAddImage, IconCopy, IconWarning, IconAssitant, IconUpload } from "icons";
import clsx from "clsx";
import { clipboardCopy } from "utils";
import { IMAGE_SIZE_LIMIT, MIN_WIDTH_HEIGHT_PROMPT_ASSISTANT_MESSAGE } from "utils/constants";
import UploadBox from "components/UploadBox";
import promptassistantService from "api/prompt-assistant/promptassistant.service";
import Button from "components/Button";

const Message = ({
  from,
  message,
  imageUpload,
  setMessages,
  setShowUserChoices,
  copyItems,
  messages,
  src,
  setIsBotWorking,
  copyPrompt,
  error,
  setShowRefreshButton,
  handleCallback,
}: {
  from: number;
  message: any;
  imageUpload?: boolean;
  setShowUserChoices: any;
  setMessages: any;
  messages: any[];
  src: string;
  setIsBotWorking: any;
  copyItems: any;
  copyPrompt: any;
  error: any;
  setShowRefreshButton: any;
  handleCallback?: (text: string) => void;
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [imageValue, setImageValue] = useState("");

  const fileInputRef = useRef<HTMLInputElement>(null);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });
  const s3 = new AWS.S3();

  function recursiveDescribeImageStatus(created_id: any) {
    promptassistantService
      .getDescribeImageStatus(created_id)
      .then((res: any) => {
        if (res.description !== "" && res.description !== undefined) {
          setMessages((prev: any) => [
            ...prev,
            { from: 0, message: "Here are your results:", imageUpload: false },
            {
              from: 0,
              message: res.description,
              imageUpload: false,
            },
          ]);
          setMessages((prev: any) => [...prev, { copyItems: [res.description] }]);
          setShowUserChoices(true);
          setIsBotWorking(false);

          return;
        }
        setTimeout(() => recursiveDescribeImageStatus(created_id), 3000);
      })
      .catch(() => {
        setIsBotWorking(false);
        setMessages((prev: any) => [...prev, { from: 0, error: true, message: "Something went wrong. Please refresh the page!", imageUpload: false, element: null }]);
        setShowRefreshButton(true);
      });
  }

  const handleImageUpload = async (url: string) => {
    try {
      setIsUploading(false);
      setImageValue(url);

      const updatedMessages = messages.map((message, index) => (index === messages.length - 1 ? { ...message, src: url } : message));
      setMessages(updatedMessages);
      setIsBotWorking(true);
      promptassistantService
        .describeImage(url)
        .then((res: any) => {
          recursiveDescribeImageStatus(res.responseData.created_id);

          // setMessages((prev: any) => [
          //   ...prev,
          //   { from: 0, message: "Here are your results:", imageUpload: false },
          //   {
          //     from: 0,
          //     message: res.prompts,
          //     imageUpload: false,
          //   },
          // ]);
          // setMessages((prev: any) => [...prev, { copyItems: res.prompts }]);
          // setShowUserChoices(true);
          // setIsBotWorking(false);
        })
        .catch(() => {
          setIsBotWorking(false);
          setMessages((prev: any) => [...prev, { from: 0, error: true, message: "Something went wrong. Please refresh the page!", imageUpload: false, element: null }]);
          setShowRefreshButton(true);
        });
    } catch (error) {
      setIsBotWorking(false);
      setMessages((prev: any) => [...prev, { from: 0, error: true, message: "Something went wrong. Please refresh the page!", imageUpload: false, element: null }]);
      setShowRefreshButton(true);
    }
  };

  const CopyItems = ({ idx, prompt }: { idx: number; prompt: string }) => {
    const [isCopied, setIsCopied] = useState(false);
    function handleCopy() {
      setIsCopied(true);
      if (handleCallback) handleCallback(prompt);
      clipboardCopy(prompt);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }

    return (
      <div className="flex-center cursor-pointer h-[38px] bg-white rounded-md gap-[6px] text-black text-h7 px-2.5" onClick={handleCopy}>
        <IconCopy className="w-[18px] h-[18px]" />
        {isCopied ? <span>Copied!</span> : idx}
      </div>
    );
  };

  const CopyPrompt = ({ prompt }: { prompt: string }) => {
    const [isCopied, setIsCopied] = useState(false);
    function handleCopy() {
      setIsCopied(true);
      if (handleCallback) handleCallback(prompt);
      clipboardCopy(prompt);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }

    return (
      <Button className="btn-secondary w-[260px] justify-start text-h7 btn-sm rounded ml-[42px] !bg-green !text-black !border-none" onClick={handleCopy}>
        <IconCopy className="w-[18px] h-[18px]" />
        {isCopied ? <span>Copied!</span> : <span>Use This Prompt</span>}
      </Button>
    );
  };

  return (
    <div className={clsx("flex gap-2.5", from === 0 ? "" : "justify-end", typeof message !== "string" ? "items-end" : "")}>
      {from === 0 && (
        <div className="flex-center w-[34px] h-[34px] bg-white rounded-full border border-border">
          <IconAssitant className=" w-[14px] h-[14px]" />
        </div>
      )}
      <div className="flex flex-col gap-[15px] w-full">
        {copyPrompt ? (
          <CopyPrompt prompt={copyPrompt} />
        ) : copyItems?.length > 0 ? (
          <div className="flex self-end gap-[5px]">
            {copyItems.map((item: any, idx: number) => (
              <CopyItems key={`copyItem${idx}`} idx={idx + 1} prompt={item} />
            ))}
          </div>
        ) : typeof message === "string" ? (
          message !== "" ? (
            <div
              className={clsx(
                "flex text-bodySm p-2 rounded-md max-w-[75%] w-fit",
                from === 0 ? "text-white gap-1.5 bg-black border border-black-300 " : "self-end text-trueBlack bg-green",
                error ? "!text-orange !border-orange" : ""
              )}
            >
              {error && <IconWarning className="w-5 h-5 text-orange" />}
              {message}
            </div>
          ) : (
            <></>
          )
        ) : (
          message.map((item: any, idx: any) => (
            <div
              key={`msgitem-${idx}`}
              className={clsx(
                "flex text-bodySm  p-2.5 rounded-md max-w-[400px] ",
                from === 0 ? "text-white bg-white bg-opacity-[15%] border border-white border-opacity-[15%]" : "text-black bg-white border border-white"
              )}
            >
              <div className="flex gap-2.5 text-bodySm">
                <div className="flex-center w-[18px] h-[18px] flex-shrink-0 bg-white rounded-[4px] text-black">{idx + 1}</div>
                <span className="text-bodySm text-white">{item}</span>
              </div>
            </div>
          ))
        )}

        {imageUpload && (
          <div className="flex w-3/4">
            <UploadBox
              location="prompt_assistant_message"
              value={src}
              isRemovable={false}
              className="!w-full"
              text="Drag & Drop to upload file or click here"
              icon={IconUpload}
              callback={(url: string) => handleImageUpload(url)}
              minHeight={MIN_WIDTH_HEIGHT_PROMPT_ASSISTANT_MESSAGE}
              minWidth={MIN_WIDTH_HEIGHT_PROMPT_ASSISTANT_MESSAGE}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Message;
