import React from "react";
import Button from "components/Button";
import Modal from "components/Modal";
import { features } from "pages/Layout/Header/components/WhatsNew";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { hideFeatureAnnouncementPopup } from "store/commonSlice";

const FeatureAnnouncementPopup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showFeatureAnnouncementPopup } = useAppSelector((state) => state.common);
  const latestFeature = features[0];

  function onClose() {
    dispatch(hideFeatureAnnouncementPopup());
  }

  return (
    <Modal onClose={onClose} show={showFeatureAnnouncementPopup} bodyClassName="!overflow-hidden !max-w-[480px]  !border-grey-light dark:!border-black-300" className="!z-[9990]">
      <div className="flex flex-col gap-5 p-6 bg-black-100 text-white">
        <h2 className="text-h2">Feature Update!</h2>

        <img src={latestFeature.image} />

        <h4 className="text-h4">{latestFeature.title}</h4>

        {latestFeature.description && latestFeature.description()}

        <div className="flex flex-col gap-2">
          {latestFeature.button && latestFeature.button({ navigateFunc: navigate, callback: onClose })}
          <Button className="btn-secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FeatureAnnouncementPopup;
