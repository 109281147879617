import React, { useRef, useState } from "react";
import { IconSearch } from "../../icons";
import clsx from "clsx";

const Search = ({ onSearch, className, searchPeriod = 1000 }: any) => {
  const textInputRef = useRef<HTMLInputElement>(null);
  const [timeoutId, setTimeoutId] = useState<any>(null);

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      const _text = event.target.value;
      console.log(_text);
    }
  };

  const handleInputChange = (e: any) => {
    const newValue = e.target.value;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      onSearch(newValue);
    }, searchPeriod);

    setTimeoutId(newTimeoutId);
  };

  return (
    <div className={clsx("flex input-container items-center w-full gap-2.5 border border-black-300 rounded-[3px] p-2.5", className)}>
      <IconSearch className="w-4 h-4 text-white flex-shrink-0" />
      <input
        ref={textInputRef}
        onKeyDown={handleKeyDown}
        className="input w-full text-white outline-none text-bodySm placeholder-grey"
        placeholder="Search"
        onChange={(e) => handleInputChange(e)}
      ></input>
    </div>
  );
};

export default Search;
