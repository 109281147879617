import { createContext, useContext } from "react";
import { Dimensions } from "./components/DesignLeftMenu";

// Define the shape of the context

export interface IAddLogo {
  image?: string;
  isActive?: boolean;
  width?: number;
  height?: number;
  angle?: number;
  coordX?: number;
  coordY?: number;
  canvasWidth?: number;
  canvasHeight?: number;
}

export interface DesignContextType {
  selectedDesignType: any;
  setSelectedDesignType: (selectedDesign: any) => void;
  selectedTool: any;
  setSelectedTool: (selectedTool: any) => void;
  currentCursor: any;
  setCurrentCursor: (currentCursor: any) => void;
  brushWidth: any;
  setBrushWidth: (instance: any) => void;
  canvasRef: any;
  contextRef: any;
  setToDraw: () => void;
  setToErase: () => void;
  redo: () => void;
  undo: () => void;
  push: (resetHistory?: boolean) => void;
  setDesignId: (id: string) => void;
  designId: string;
  images: any;
  setImages: (images: any) => void;
  selectedImageIndex: number;
  setSelectedImageIndex: (idx: number) => void;
  cPushArray: any;
  clippedImg: any;
  setClippedImg: (data: any) => void;
  isLoading: any;
  setIsLoading: (bool: boolean) => void;
  alreadyStartedCreating: any;
  setAlreadyStartedCreating: (bool: boolean) => void;
  showPatternModal: any;
  setShowPatternModal: (bool: boolean) => void;
  isCanvasDrawn: boolean;
  cStep: any;
  isDeleting: boolean;
  setIsDeleting: (bool: boolean) => void;
  scaledPattern: any;
  setScaledPattern: (val: any) => void;
  selectedMaskArray: any;
  setSelectedMaskArray: (data: any) => void;
  resetLeftMenu: boolean;
  setResetLeftMenu: (val: boolean) => void;
  isOnLoadingItem: boolean;
  setIsOnLoadingItem: (val: boolean) => void;
  imagesInProcess: any;
  setImagesInProcess: (images: any) => void;
  addLogoState: IAddLogo;
  setAddLogoState: (data: any) => void;
  selectedDimension: Dimensions;
  setSelectedDimension: (dimension: Dimensions) => void;
  showGetFeedback: boolean;
  setShowGetFeedback: (show: boolean) => void;
  magicSelectRef: any;
  isColorPickerActive: boolean;
  setIsColorPickerActive: (val: any) => void;
  pickedColor: string;
  setPickedColor: (val: any) => void;
  redrawLatestCanvasState: () => void;
  resetCanvas: () => void;
}

// Create the context
const DesignContext = createContext<DesignContextType | undefined>(undefined);

export const useDesignContext = () => {
  const context = useContext(DesignContext);
  if (!context) {
    throw new Error("useDesignContext must be used within a ThemeProvider");
  }

  return context;
};

export default DesignContext;
