import React, { useRef, useState } from "react";
import clsx from "clsx";
import Button from "components/Button";
import { useClickOutside } from "hooks/useClickOutside";
import { IconTrash } from "icons";
import Tooltip from "components/Tooltip";
import { useDispatch } from "react-redux";
import { setSnackbar } from "store/commonSlice";

const DeleteMenu = ({ onDelete }: any) => (
  <div className="flex flex-col w-[200px] rounded-[5px] bg-black border border-black-300">
    <span className="text-bodySm text-white px-2.5 py-3">Are you sure that you want to delete this image?</span>
    <div className="flex gap-2.5 p-2.5 border-t border-black-300">
      <Button className="btn-secondary w-full">Cancel</Button>
      <Button className="btn-warning w-full" onClick={(e) => onDelete(e)}>
        Delete
      </Button>
    </div>
  </div>
);

const DeleteButton = ({ onDeleteImage, data, className, tooltipPosition }: any) => {
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const [showDeleteMenu, setShowDeleteMenu] = useState(false);
  const deleteMenuRef = useRef<HTMLDivElement>(null);

  useClickOutside(deleteMenuRef, () => {
    setShowDeleteMenu(false);
  });

  async function onDelete(e: any) {
    e.stopPropagation();
    if (data) {
      onDeleteImage(data.id, data.image_id)
        .then(() => setShowDeleteMenu(false))
        .catch(() => {
          dispatch(setSnackbar({ message: "Failed to delete image", type: "error" }));
          setShowDeleteMenu(false);
        });
    } else
      onDeleteImage()
        .then(() => setShowDeleteMenu(false))
        .catch(() => {
          dispatch(setSnackbar({ message: "Failed to delete image", type: "error" }));
          setShowDeleteMenu(false);
        });
  }

  function calculateDistanceToLeft(ref: any) {
    if (ref.current) {
      return ref.current.getBoundingClientRect().left;
    }

    return 0;
  }

  return (
    <div className="relative" ref={containerRef}>
      <Tooltip content="Delete" position={tooltipPosition ?? "top"}>
        <Button
          className={clsx("btn-warning w-[26px] h-[26px]", showDeleteMenu ? "pointer-events-none" : "", className)}
          onClick={(e) => {
            e.stopPropagation();
            setShowDeleteMenu(true);
          }}
        >
          <IconTrash className="w-4 h-4 flex-shrink-0" />
        </Button>
      </Tooltip>
      {showDeleteMenu && (
        <div
          ref={deleteMenuRef}
          className="absolute bottom-0 z-50"
          style={{ left: calculateDistanceToLeft(containerRef) < 430 ? "calc(100% + 6px)" : "", right: calculateDistanceToLeft(containerRef) < 430 ? "" : "calc(100% + 6px)" }}
        >
          <DeleteMenu onDelete={(e: any) => onDelete(e)} />
        </div>
      )}
    </div>
  );
};

export default DeleteButton;
