import React, { useMemo, useState } from "react";
import Tab from "components/Tab";
import { IconBilling, IconSettings, IconShared, IconSupport } from "icons";
import AccountSettings from "./components/AccountSettings";
import ManageMembers from "./components/ManageMembers";
import PlanBilling from "./components/PlanBilling";
import Support from "./components/Support";
import { useAppSelector } from "store";
import userService from "api/user/user.service";
import { useDispatch } from "react-redux";
import { setAccountSelectedMenu, setUser } from "store/commonSlice";

export enum AccountSelectMenu {
  AccountSettings,
  ManageMembers,
  PlanBilling,
  Support,
}

const menuItems = [
  { title: "Account Settings", icon: IconSettings },
  { title: "Manage Members", icon: IconShared },
  { title: "Plan & Billing", icon: IconBilling },
  { title: "Support", icon: IconSupport },
];

const Account = () => {
  const dispatch = useDispatch();
  const { user, accountSelectedMenu } = useAppSelector((state) => state.common);
  const [selectedMenu, setSelectedMenu] = useState(accountSelectedMenu);

  React.useEffect(() => {
    setSelectedMenu(accountSelectedMenu);

    return () => {
      dispatch(setAccountSelectedMenu(AccountSelectMenu.AccountSettings));
    };
  }, [accountSelectedMenu]);

  React.useEffect(() => {
    if (user.id === "" || user.id === undefined) return;

    userService
      .getUserDetail({
        user_id: user.id,
      })
      .then((res) => {
        const packageDetail = res.responseData.package_detail;

        const designPackage = packageDetail.find((item: any) => item.package_type === "design");
        const photoshootPackage = packageDetail.find((item: any) => item.package_type === "photoshoot");

        const packageCodes = [designPackage?.code, photoshootPackage?.code];

        dispatch(
          setUser({
            id: res?.responseData?.user_info.id,
            name: res?.responseData?.user_info.name,
            email: res?.responseData?.user_info.email,
            createPeriod: res.responseData?.last_created_info.created_period,
            profilePhoto: res.responseData?.user_info.profile_photo,
            packageCode: packageCodes,
            use_ghostphotoshoot: photoshootPackage?.use_ghostphotoshoot === "True",
            use_mannequin: photoshootPackage?.use_mannequin === "True",
          })
        );
      });
  }, [user.id]);

  const Menu = useMemo(() => {
    switch (selectedMenu) {
      case AccountSelectMenu.AccountSettings:
        return <AccountSettings />;
      case AccountSelectMenu.ManageMembers:
        return <ManageMembers />;
      case AccountSelectMenu.PlanBilling:
        return <PlanBilling />;
      case AccountSelectMenu.Support:
        return <Support />;
      default:
        return <></>;
    }
  }, [selectedMenu]);

  return (
    <div className="flex flex-col h-full w-full gap-5 p-5">
      <div className="flex w-full">
        <h2 className="text-h2 text-white">Account</h2>
      </div>
      <div className="flex flex-col p-1.5 rounded-md bg-black-200 border border-black-300">
        <Tab initTab={selectedMenu} onChange={(value: any) => setSelectedMenu(value)} className="w-fit tab2">
          {menuItems.map((item, index) => (
            <Tab.Item key={`account_menu_item_${index}`} id={index} className="!bg-black-200">
              <div className="flex gap-2.5">
                <item.icon className="w-[18px] h-[18px]" />
                {item.title}
              </div>
            </Tab.Item>
          ))}
        </Tab>
      </div>
      {Menu}
    </div>
  );
};

export default Account;
