import React from "react";
import clsx from "clsx";
import { IconCheck, IconWarning } from "icons";

interface IInput {
  icon?: React.ReactNode;
  className?: string;
  containerClassName?: string;
  error?: string;
  message?: string;

  [key: string]: any;
}

const Input = ({ className, containerClassName, icon, error, message, ...etc }: IInput, ref: any) => {
  return (
    <div className="flex flex-col gap-2.5 w-full">
      <div className={clsx("flex flex-col border border-black-300 rounded-[5px] ", containerClassName)}>
        <div className={clsx("input-container flex flex-row items-center px-2.5 py-3 rounded-[4px]", containerClassName)}>
          <input ref={ref} className={clsx("input", className)} {...etc} />
          {icon}
        </div>
        {error && (
          <span className="flex text-orange text-bodySm items-center gap-[5px] p-[5px]" style={{ background: "rgba(232, 128, 83, 0.1)" }}>
            <IconWarning className="h-[14px] w-[14px] flex-shrink-0" />
            {error}
          </span>
        )}
        {message !== "" && message && (
          <span className="flex text-purple text-bodySm items-center gap-[5px] p-[5px]" style={{ background: "rgba(98, 102, 240, 0.10)" }}>
            <IconCheck className="h-[14px] w-[14px] flex-shrink-0" />
            {message}
          </span>
        )}
      </div>
    </div>
  );
};

export default React.forwardRef(Input);
