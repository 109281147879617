import React from "react";
import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    name: yup.string().required("Please enter a name!"),
  })
  .required();

const RenameModal = ({ onClose, show, trainId, name, onEdit }: any) => {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onValid = async (data: any) => {
    await onEdit(data.name);
    onClose();
  };

  const onHandleSubmit = () => {
    handleSubmit(
      (data) => onValid(data),
      (e) => console.log(e)
    )();
  };

  function handleOnClose() {
    onClose();
    resetField("name");
  }

  return (
    <Modal onClose={handleOnClose} show={show} bodyClassName="!rounded-[5px] !border-none">
      <div className="flex flex-col bg-black-100 bg-opacity-30 w-[300px]">
        <div className="p-2 bg-black-300 text-h6 text-white rounded-t-[5px]">Rename</div>
        <div className="flex flex-col gap-3 w-full p-3 bg-black">
          <Input className="w-full" defaultValue={name} error={errors.name?.message} {...register("name")} />
        </div>
        <div className="flex bg-black-100 gap-2.5 p-2.5 border border-black-300 rounded-b-[5px]">
          <Button className="btn-secondary w-full" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button className="btn-primary w-full" onClick={onHandleSubmit}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RenameModal;
