import React from "react";
import { IconAnalyze, IconEdit, IconMoveFile, IconOpen, IconShare, IconTrash, IconUnshare } from "icons";
import { useCollectionsContext } from "../CollectionsContext";
import clsx from "clsx";
import collectionsService from "api/collections/collections.service";
import { useNavigate } from "react-router-dom";
import { AnalyticsEvent } from "utils";

const ActionMenu = ({ data, isTraining = true, setShowActionMenu, setShowRenameModal, setShowDeleteModal, imageError }: any) => {
  const navigate = useNavigate();
  const { onSharedWithMePage } = useCollectionsContext();
  const MenuItem = ({ icon, title, onClick, hidden }: any) => {
    const Icon = icon;

    return (
      <div
        className={clsx("cursor-pointer  items-center p-2.5 gap-2.5 text-grey text-bodyMd hover:text-white hover:bg-black-300 rounded-[4px]", hidden ? "hidden" : "flex")}
        onClick={() => {
          setShowActionMenu(false);
          onClick();
        }}
      >
        <Icon className="w-[18px] h-[18px]" />
        {title}
      </div>
    );
  };

  return (
    <div className="flex flex-col bg-black rounded-[5px] p-1.5 w-[200px] border border-black-300">
      {!imageError && isTraining && (
        <MenuItem
          icon={IconOpen}
          title="Open Folder"
          onClick={() => {
            if (window.location.pathname === "/") AnalyticsEvent("homepage_collections_open_folder", "", "click");
            else AnalyticsEvent("mycollections_open_folder", "", "click");
            navigate(`/collections/${data.id}`);
          }}
        />
      )}
      <MenuItem
        hidden={onSharedWithMePage}
        icon={IconEdit}
        title="Rename"
        onClick={() => {
          if (window.location.pathname === "/") AnalyticsEvent("homepage_collections_rename", "", "click");
          else AnalyticsEvent("mycollections_rename", "", "click");

          setShowRenameModal(true);
        }}
      />
      {/* <MenuItem icon={IconMoveFile} title="Move" onClick={() => console.log("move")} /> */}
      {/* <MenuItem hidden={onSharedWithMePage} icon={IconUnshare} title="Unshare" onClick={() => console.log("unshare")} />
      <MenuItem hidden={onSharedWithMePage} icon={IconShare} title="Share" onClick={() => console.log("share")} /> */}
      <MenuItem
        hidden={onSharedWithMePage}
        icon={IconTrash}
        title="Delete"
        onClick={() => {
          if (window.location.pathname === "/") AnalyticsEvent("homepage_collections_delete", "", "click");
          else AnalyticsEvent("mycollections_delete", "", "click");

          setShowDeleteModal(true);
        }}
      />
    </div>
  );
};

export default ActionMenu;
