import React, { useRef, useState } from "react";
import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import Select from "./Select";
import { EMAIL_REGEX } from "utils/constants";
import { IconWarning } from "icons";
import clsx from "clsx";

const AddUser = ({ onClose, show, handleInviteUsers }: any) => {
  const [selectedRole, setSelectedRole] = useState("Viewer");
  const [inputError, setInputError] = useState("");
  const [isInviting, setIsInviting] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  function onInviteMembers() {
    setIsInviting(true);

    if (!inputRef.current) return;

    const input = inputRef.current.value;
    if (input === "") {
      setInputError("Please enter a email address!");
      setIsInviting(false);

      return;
    }

    const emailsArray = input.split(",").map((email) => email.trim());
    const invalidEmailList: string[] = [];
    const validEmailList: string[] = [];

    emailsArray.forEach((email) => {
      if (!EMAIL_REGEX.test(email)) {
        invalidEmailList.push(email);
      } else {
        validEmailList.push(email);
      }
    });

    if (invalidEmailList.length > 0) {
      setInputError("Followings are not valid email addresses: \n" + invalidEmailList.join(",  "));
      setIsInviting(false);

      return;
    }

    setInputError("");
    handleInviteUsers(validEmailList, selectedRole);
  }

  function handleClose() {
    setInputError("");
    setIsInviting(false);
    onClose();
  }

  return (
    <Modal onClose={handleClose} show={show} bodyClassName="!rounded-[5px] !border-none">
      <div className="flex flex-col bg-black w-[420px]">
        <div className="p-2 bg-black-300 text-h6 text-white rounded-t-[5px]">Add User</div>
        <div className="flex flex-col m-2.5">
          <div className={clsx("flex items-center border border-black-300", inputError ? "rounded-b-none" : "rounded-md")}>
            <Input ref={inputRef} placeholder="Enter email, comma separated" containerClassName="!border-none" />
            <div className="flex-center bg-black-300 py-3">
              <Select
                containerClassName="flex-center w-[100px] px-2.5"
                menuContainerClassName="!w-[100px]"
                bodyClassName=""
                values={["Admin", "Editor", "Viewer"]}
                defaultValue={["Viewer"]}
                selectCallback={(value: any) => setSelectedRole(value)}
              />
            </div>
          </div>
          {inputError && (
            <div className="flex items-center p-[5px] gap-[5px] rounded-b-[5px]" style={{ background: "rgba(232, 128, 83, 0.1)" }}>
              <IconWarning className="text-orange w-[14px] h-[14px]" />
              <p className="text-bodySmall text-orange pl-[1px]">{inputError}</p>
            </div>
          )}
        </div>
        <div className="flex bg-black-100 gap-2.5 p-2.5 border border-black-300 rounded-b-[5px]">
          <Button className="btn-secondary w-full" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="btn-primary w-full"
            onClick={() => {
              onInviteMembers();
            }}
            disabled={isInviting}
          >
            Invite Members
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddUser;
