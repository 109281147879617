import { RefabricProURL } from "../index";
import { ApiResponse } from "../HttpClient";

export default {
  async describePrompt(prompt: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`/prompt-assistant/describe_prompt`, { prompt: prompt }, {});
  },
  async describeImage(img_path: string): Promise<ApiResponse<any>> {
    return await RefabricProURL.post(`/prompt-assistant/describe_image`, { img_path: img_path }, {});
  },
  async getDescribeImageStatus(get_created_id: any): Promise<ApiResponse<any>> {
    return await RefabricProURL.get(`/prompt-assistant/describe_image/${get_created_id}`, {});
  },
};
